import React, { useEffect, useState } from 'react';
import { FaCross } from 'react-icons/fa';
import { useAPI } from 'src/Components/API';
import Button from 'src/Components/Button';
import styled from '@emotion/styled';
import useBridge from 'src/Components/Bridge';
import { useUniverse } from 'src/Components/Universe';

const Wrapper = styled.div`
  height: 100vh;
  background-color: #aec6ce;
  padding: 20px;
`;

const Content = styled.div`
  padding: 20px 0;
  height: calc(100vh - 40px);
  padding-bottom: 100px;
  position: relative;
  background-color: #f5f5dd;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const BankWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 165px);
  margin: 0 20px;
`;

const Bank = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const BankDivider = styled.div`
  padding: 10px 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 60px;
`;

const Checkbox = styled.div``;

const Logo = styled.img`
  width: 50px;
  flex: 0 0 50px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  flex: 1;
  padding: 0 15px;
`;

const CountryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Country = styled.div`
  flex: 0 0 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc((100vw - 40px) / 4);
  border: 0px solid #ccc;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));

  cursor: pointer;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
`;

export interface BankItem {
  id: string;
  name: string;
  bic: string;
  transaction_total_days: string;
  countries: string[];
  logo: string;
}

interface Props {
  showCloseButton?: boolean;
}

const Connect: React.FC<Props> = ({ showCloseButton }) => {
  const bridge = useBridge();
  const [loading, setLoading] = useState(false);
  const [bankLink, setBankLink] = useState('');
  const [banks, setBanks] = useState<BankItem[]>([]);
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const [country, setCountry] = useState<null | string>('SE');
  const countries = [
    'SE',
    'NO',
    'DK',
    'FI',
    'DE',
    'ES',
    'NL',
    'GB',
    'PT',
    'IT',
    'BE',
    'FR',
  ];
  const uniqueCountries = [...new Set(countries)];
  const api = useAPI();
  const universe = useUniverse();

  useEffect(() => {
    if (!country) {
      return;
    }

    api.banks(country).then((response) => setBanks(response));
  }, [country]);

  useEffect(() => {
    if (!selectedBank) {
      return;
    }

    setLoading(true);

    api
      .createBankSession(selectedBank, window.location.href)
      .then((response) => {
        setBankLink(response.link);
        setLoading(false);
      });
  }, [selectedBank]);

  /**
   * Refetch the universe and check if a connection has been established
   *
   * If we have a connection, we will redirect
   */
  useEffect(() => {
    if (universe.loading) {
      return;
    }

    const id = setTimeout(() => {
      universe.refetch();
    }, 3000);

    return () => clearTimeout(id);
  }, [universe]);

  function handleSubmit() {
    if (!bankLink) {
      return;
    }

    bridge.open(bankLink);
  }

  return (
    <Wrapper>
      <Content>
        {country ? (
          <>
            <Title>Select your bank</Title>
            <BankWrapper>
              {banks.map((institution) => (
                <Bank
                  key={institution.id}
                  onClick={() => {
                    setSelectedBank(institution.id);
                  }}
                >
                  <Logo src={institution.logo} alt={institution.name} />
                  <Name>
                    {selectedBank === institution.id && '➡'} {institution.name}
                  </Name>
                </Bank>
              ))}
            </BankWrapper>
            <BankDivider>
              <Button disabled={loading} onClick={handleSubmit}>
                {loading ? 'Loading...' : 'Connect'}
              </Button>
            </BankDivider>
          </>
        ) : (
          <>
            <Title>Choose your country</Title>
            <CountryWrapper>
              {uniqueCountries.map((country) => (
                <Country
                  onClick={() => {
                    setCountry(country);
                  }}
                >
                  <img
                    src={`https://flagcdn.com/h80/${country.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/h80/${country.toLowerCase()}.png 2x`}
                    alt={country}
                  ></img>
                </Country>
              ))}
            </CountryWrapper>
          </>
        )}
      </Content>

      {showCloseButton && (
        <CloseButton>
          <Button onClick={() => window.history.back()}>X</Button>
        </CloseButton>
      )}
    </Wrapper>
  );
};

export default Connect;
