import { useState } from 'react';
import styled from '@emotion/styled';
import { useAPI } from '../API';
import {
  FaChartLine,
  FaHouseUser,
  FaPiggyBank,
  FaTrophy,
} from 'react-icons/fa';

const Wrapper = styled.div`
  background: #aeaeae;
  flex: 0 0 95px;
  display: flex;
  bottom: 0;
  justify-content: space-between;

  z-index: 10;

  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 3;
  height: calc(35px + env(safe-area-inset-bottom, 20px));
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(33, 33, 33, 0.8);
  backdrop-filter: blur(6px);
  color: #fff;
  font-size: 1.1rem;
  box-sizing: content-box;
`;

const Section = styled.div<{
  active?: boolean;
}>`
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;

  position: relative;

  background: ${(props: any) =>
    props.active ? 'rgba(255,255,255,0.1) ' : 'transparent'};
  padding: 16px;
  padding-top: ${(props) => (props.active ? 13 : 16)}px;
  flex: 1;
  cursor: pointer;
  border-bottom: 5px solid
    ${(props: any) => (props.active ? '#333 ' : 'transparent')};

  transition: all 0.15s ease-in-out;
`;

const SectionIcon = styled.div`
  width: 0px;
  height: 0px;
  margin: 0 auto;
  background: transparent;
  position: relative;
  top: 4px;
  right: 12px;
`;

const SectionTitle = styled.div`
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
`;

const AddButton = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 30px;
  z-index: 4;
  left: calc(50% - 20px);
  background: #000;
  cursor: pointer;
  outline: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 2px 2px 0px rgba(30, 30, 30, 0.3);
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  text-align: center;
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
`;

const OptionsWrapper = styled.div`
  position: absolute;
  bottom: 150px;
  width: 200px;
  transform: translateX(-100px);
  left: 50%;
  text-align: center;
`;

const Option = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  background: #262626;
  margin: 6px;
  display: inline-block;
  border-radius: 3px;
  transition: all 0.2s ease-out;
  border: 5px solid transparent;
  cursor: pointer;

  &:hover {
    border: 5px solid #fff;
  }
`;

interface Props {
  currentRoute: string;
  onRouteChange: (route: string) => void;
}

const Navbar: React.FC<Props> = ({ currentRoute, onRouteChange }) => {
  const api = useAPI();
  const [clicked, setClicked] = useState(false);

  return (
    <Wrapper>
      {clicked && <Background onClick={() => setClicked(false)} />}

      {clicked && (
        <OptionsWrapper>
          <Option
            onClick={async () => {
              onRouteChange('create-expense');
              setClicked(false);
            }}
          >
            + Add Expense
          </Option>
          <Option
            onClick={async () => {
              onRouteChange('create-savings');
              setClicked(false);
            }}
          >
            + Add Saving
          </Option>
          <Option
            onClick={async () => {
              onRouteChange('create-dream');
              setClicked(false);
            }}
          >
            + Add Dream
          </Option>
        </OptionsWrapper>
      )}

      <Section
        active={currentRoute === 'home'}
        onTouchStart={() => onRouteChange('home')}
        onClick={() => onRouteChange('home')}
      >
        <SectionIcon>
          <FaHouseUser />
        </SectionIcon>
        <SectionTitle>Home</SectionTitle>
      </Section>

      <Section
        active={currentRoute === 'insights'}
        onTouchStart={() => onRouteChange('insights')}
        onClick={() => onRouteChange('insights')}
      >
        <SectionIcon>
          <FaChartLine />
        </SectionIcon>
        <SectionTitle>Explore</SectionTitle>
      </Section>

      <Section
        active={currentRoute === 'dreams'}
        onTouchStart={() => onRouteChange('dreams')}
        onClick={() => onRouteChange('dreams')}
      >
        <SectionIcon>
          <FaTrophy />
        </SectionIcon>
        <SectionTitle>Dream</SectionTitle>
      </Section>

      <Section
        active={currentRoute === 'savings'}
        onTouchStart={() => onRouteChange('savings')}
        onClick={() => onRouteChange('savings')}
      >
        <SectionIcon>
          <FaPiggyBank />
        </SectionIcon>
        <SectionTitle>Save</SectionTitle>
      </Section>
    </Wrapper>
  );
};

export default Navbar;
