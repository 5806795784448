import React, { useEffect, useLayoutEffect, useState } from 'react';
import Button from 'src/Components/Button';
import styled from '@emotion/styled';
import { Formik, Field, Form, useFormik } from 'formik';
import { useAPI } from '../../Components/API';

// Import the functions you need from the SDKs you need
import { FirebaseError, initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  OAuthProvider,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  getRedirectResult,
  signOut,
  User,
  UserCredential,
} from 'firebase/auth';
import { Content, Wrapper } from 'src/Components/Layout';
import { useLogger } from 'src/Components/Logger';
import useBridge from 'src/Components/Bridge';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC2DRFgIXf5s13elezfhJufsl7kmhYIy0A',
  authDomain: 'joar-s-money-app.firebaseapp.com',
  projectId: 'joar-s-money-app',
  storageBucket: 'joar-s-money-app.appspot.com',
  messagingSenderId: '122573595263',
  appId: '1:122573595263:web:ed22deb03adfb78f95554a',
  measurementId: 'G-KRK2RXFBQG',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

const googleProvider = new GoogleAuthProvider();

const PageWrapper = styled(Wrapper)`
  background-image: url('https://i.imgur.com/6F8VFDo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
`;

const Title = styled.div`
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
`;

const Input = styled.input<{
  hasError?: boolean;
}>`
  padding: 6px;
  font-size: 16px;
  border: 1px solid ${(props) => (props.hasError ? 'red' : '#ccc')};
  background: #fff;
  margin-top: 10px;
  width: 100%;
  border-radius: 3px;
`;

const ErrorLabel = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const AppleButton = styled.div`
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 85%;
  margin-bottom: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
`;

const Divider = styled.div`
  margin: 30px 0;
  position: relative;
`;

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ccc;
`;

const DividerText = styled.div`
  padding: 3px 8px;
  background: #f5f5dd;
  color: #555;
  margin: 0 auto;
  position: absolute;
  top: -12px;
  left: 46%;
`;

const LoginRegister = () => {
  const bridge = useBridge();

  const form = useFormik({
    onSubmit: (values) => {
      console.debug('submit', values);
      login('email', values);
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Required';
      }
      if (!values.name) {
        errors.name = 'Required';
      }
      return errors;
    },
    initialValues: {
      email: '',
      password: '',
      name: '',
    },
  });
  const logger = useLogger('login');
  const [error, setError] = useState<
    'email' | 'name' | 'password' | 'anonymous' | null
  >(null);
  const api = useAPI();
  const [pleaseWaitOverlay, setPleaseWaitOverlay] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function handle(event: Event) {
      const parsed: {
        type: string;
        payload: any;

        // @ts-ignore
      } = event.data;
      if (parsed.type === 'auth') {
        setPleaseWaitOverlay(true);
        const { token, name } = parsed.payload;

        const loginResult = await api.login({
          id: token,
          name,
        });

        localStorage.setItem('token', loginResult.token);

        bridge.reload();
      }
    }

    document.addEventListener('message', handle);

    return () => document.removeEventListener('message', handle);
  }, []);

  async function handleSuccess(user: User, name?: string) {
    setPleaseWaitOverlay(true);

    const id = await user.getIdToken();

    const loginResult = await api.login({
      id,
      name: name || user.displayName || user.email || 'Anonymous',
    });

    window.alert(JSON.stringify(loginResult));

    if (!loginResult.token) {
      throw new Error(loginResult);
    }

    localStorage.setItem('token', loginResult.token);
    bridge.reload();
  }

  async function login(
    provider: 'google' | 'apple' | 'email' | 'anonymous',
    data?: any,
  ) {
    let result: UserCredential;
    logger.info('Logging in with ' + provider);
    setLoading(true);

    try {
      if (provider === 'google') {
        window.ReactNativeWebView?.postMessage('login.google');
        //result = await signInWithRedirect(auth, googleProvider);
      } else if (provider === 'apple') {
        // Send message to react native wrapper
        window.ReactNativeWebView?.postMessage('login.apple');
        // setPleaseWaitOverlay(true);
        // result = await signInWithRedirect(auth, appleProvider);
      } else if (provider === 'anonymous') {
        window.ReactNativeWebView?.postMessage('login.anonymous');
      } else {
        if (!data?.name) {
          setError('name');
          setLoading(false);
          return;
        }

        try {
          result = await signInWithEmailAndPassword(
            auth,
            data.email,
            data.password,
          );
        } catch (e) {
          result = await createUserWithEmailAndPassword(
            auth,
            data.email,
            data.password,
          );
        }

        logger.info('Login Result', { result });
        await handleSuccess(result.user, data?.name);
      }
    } catch (e) {
      console.error(e);

      if (e instanceof FirebaseError) {
        if (e.code === 'auth/invalid-email') {
          setError('email');
        } else if (e.code === 'auth/weak-password') {
          setError('password');
        } else if (e.code === 'auth/email-already-in-use') {
          setError('email');
        }
      }
    } finally {
      setPleaseWaitOverlay(false);
      setLoading(false);
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      getRedirectResult(auth).then((result) => {
        logger.info('Redirect result 2', { result });
      });
    }, 1000);
    getRedirectResult(auth)
      .then((result) => {
        logger.info('Redirect result', { result });
        if (!result) {
          logger.warn('No result');
          return;
        }

        setPleaseWaitOverlay(true);
        setLoading(true);

        const credential = OAuthProvider.credentialFromResult(result);

        if (credential) {
          // You can also get the Apple OAuth Access and ID Tokens.
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
        }

        // The signed-in user info.
        const user = result.user;

        if (!user) {
          console.error('No user');
          return;
        }

        handleSuccess(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);
        window.alert(JSON.stringify(error));

        logger.error(error);
      });
  }, []);

  return (
    <PageWrapper>
      <Content>
        <img
          style={{
            width: '190px',
            margin: '5px',
          }}
          alt="Log in with Apple"
          src="https://developer.apple.com/design/human-interface-guidelines/technologies/sign-in-with-apple/images/apple-id-sign-up-with_2x.png"
          onClick={() => login('apple')}
        />

        <button
          type="button"
          onClick={() => login('google')}
          className="login-with-google-btn"
        >
          Sign up with Google
        </button>

        <br />
        <br />
        <button
          type="button"
          onClick={() => login('anonymous')}
          className="login-with-google-btn"
        >
          Anonymous Signup
        </button>

        <Divider>
          <DividerLine />
          <DividerText>Or</DividerText>
        </Divider>

        <form>
          <Input
            name="name"
            hasError={!!form.errors.name}
            placeholder="Name"
            value={form.values.name}
            onChange={form.handleChange}
          />
          {form.dirty && form.errors.name && (
            <ErrorLabel>{form.errors.email}</ErrorLabel>
          )}

          <Input
            name="email"
            type="email"
            placeholder="Email"
            hasError={!!form.errors.email}
            value={form.values.email}
            onChange={form.handleChange}
          />
          {form.dirty && form.errors.email && (
            <ErrorLabel>{form.errors.email}</ErrorLabel>
          )}

          <Input
            name="password"
            type="password"
            placeholder="Password"
            hasError={!!form.errors.password}
            value={form.values.password}
            onChange={form.handleChange}
          />
          {form.dirty && form.errors.password && (
            <ErrorLabel>{form.errors.password}</ErrorLabel>
          )}

          <br />
          <br />

          <Button
            disabled={loading || !form.isValid}
            onClick={() => {
              form.handleSubmit();
            }}
            type="submit"
          >
            Sign up
          </Button>
        </form>

        {pleaseWaitOverlay && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '3px',
              }}
            >
              Please wait...
            </div>
          </div>
        )}
      </Content>

      <AppleButton onClick={() => login('apple')}></AppleButton>
    </PageWrapper>
  );
};

export default LoginRegister;
