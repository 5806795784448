import React, { useState } from 'react';
import { useAPI } from 'src/Components/API';
import Button from 'src/Components/Button';
import { useUniverse } from 'src/Components/Universe';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Intro = styled.div``;

const Image = styled.img`
  background: #00000040;
`;

const Savings = () => {
  const [loading, setLoading] = useState(false);
  const universe = useUniverse();
  const api = useAPI();
  const [buttonClicked, setButtonClicked] = useState(false);

  async function handleActivate() {
    setLoading(true);
    await api.updateUserSettings({ activateSavings: true });
    await universe.refetch();
    setLoading(false);
  }

  return (
    <Wrapper>
      <Title>Turn your everyday choices into long-term wealth</Title>
      <div>
        <Intro>
          Any time your score goes above <b>1000</b>, the money you didnt spend
          will be automatically invested into a stock, fund or savings account.
          <br />
          <br />
        </Intro>
        <Image />
        <br /> <br />
        <i>
          <small>
            Stocks or funds or separate savings account not implemented yet -
            currently earmarks some money on your active account as savings
            <br />
            <br />
          </small>
        </i>
      </div>

      {!universe.savingsActivated ? (
        <Button disabled={loading} onClick={handleActivate}>
          {loading ? '✨Enabling...✨' : '✨Enable Savings✨'}
        </Button>
      ) : (
        <Title>Activated! 🙌</Title>
      )}
    </Wrapper>
  );
};

export default Savings;
