import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import data from '../../mock-data/insights.json';
import { useUniverse } from '../../Components/Universe';
import categoryLabels from '../../mock-data/categories.json';
import Graph from './Components/Graph';
import { useGroupedTransactions } from '../../Components/Transactions';
import dayjs from 'dayjs';
import { ResponsiveContainer } from 'recharts';
import { useAPI } from 'src/Components/API';

import PuffLoader from 'react-spinners/PuffLoader';
import { Centre, FadeIn } from 'src/Components/Layout';
import { useFinance } from 'src/Components/FinanceProvider';

const Wrapper = styled.div`
  padding: 20px;
  padding-bottom: 200px;
`;

const Category = styled.div`
  font-size: 18px;
`;

const Insight = styled.div`
  font-size: 16px;
  white-space: pre-wrap;
`;

const Insights = () => {
  const api = useAPI();

  const finance = useFinance();

  const universe = useUniverse();
  const transactions = useGroupedTransactions(
    {
      amount_gt: -3000000,
      start: dayjs().subtract(1, 'month'),
    },
    'DAY',
  );

  const hourly = useGroupedTransactions(
    {
      amount_gt: -3000000,
    },
    'HOUR',
    true,
  );

  const categories = universe.transactions.reduce(
    (obj, tx) => {
      const id = tx.categoryId;
      const category = categoryLabels.find((c) => c.id === id);

      if (!category) {
        return obj;
      }

      obj[id] ??= {
        id: id,
        name: category.primaryName + ': ' + category.secondaryName,
        amount: 0,
      };

      obj[id].amount += tx.amount;

      return obj;
    },
    {} as Record<
      string,
      {
        id: string;
        name: string;
        amount: number;
      }
    >,
  );

  const mapped = universe.transactions
    .filter((tx) => tx.tags.length > 0)
    .reduce(
      (obj, tx) => {
        const id = tx.tags[0].id;

        obj[id] ??= {
          id: id,
          name: tx.tags[0].title,
          amount: 0,
        };

        obj[id].amount += tx.amount;

        return obj;
      },
      {} as Record<
        string,
        {
          id: string;
          name: string;
          amount: number;
        }
      >,
    );

  const spendingValues = transactions
    .map((day) => ({
      label: day.formattedDate,
      value: day.amount / 100,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const hours = hourly.map((hour) => ({
    label: hour.formattedDate,
    value: hour.amount / 100,
  }));

  const categoryValues = Object.values(categories)
    .sort((a, b) => (a.amount < b.amount ? 1 : -1))
    .map((c) => ({
      label: c.name,
      value: c.amount / 100,
    }));

  const tagValues = Object.values(mapped)
    .sort((a, b) => (a.amount < b.amount ? 1 : -1))
    .map((c) => ({
      label: c.name,
      value: c.amount / 100,
    }));

  const dayOfWeek = universe.transactions
    .filter((tx) => !tx.ignore)
    .reduce(
      (obj, tx) => {
        const day = dayjs(tx.createdAt).day().toString();

        obj[day] ??= {
          label: day,
          value: 0,
        };

        obj[day].value += tx.amount;

        return obj;
      },
      {} as Record<
        string,
        {
          label: string;
          value: number;
        }
      >,
    );

  return (
    <Wrapper>
      <Graph title="Spend per day" values={spendingValues} />
      <Graph title="Hour of the day" values={hours} />
      <Graph title="Categories" values={categoryValues} />
      {tagValues.length > 0 && <Graph title="Tags" values={tagValues} />}
    </Wrapper>
  );
};

export default Insights;
