import styled from '@emotion/styled';
import Fuse from 'fuse.js';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Transaction as TransactionType,
  useUniverse,
} from '../../../../Components/Universe';
import TransactionOverlay from '../../../../Scenes/TransactionOverlay';
import { FaTag, FaToggleOff, FaXing } from 'react-icons/fa';
import Transaction from './Transaction';
import React from 'react';

const Wrapper = styled.div`
  position: relative;
  padding: 10px;
  padding-bottom: 480px;
`;

const TransactionTitle = styled.div`
  flex: 1;
`;

const Date = styled.div`
  font-size: 12px;
  flex: 0 0 45px;
`;

const SearchInput = styled.input`
  margin: 10px 0;
  background: rgba(20, 20, 20, 0.1);
  padding: 15px;
  border: 0;
  width: calc(100%);
  border-radius: 2px;
  outline: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  font-size: 14px;
`;

const CancelSearch = styled.div`
  background: #444;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 30px;
  right: 20px;
`;
const Price = styled.div``;

const DayWrapper = styled.div`
  margin: 10px 0;
  padding-bottom: 10px;
`;

const formatter = Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

const Transactions = () => {
  const [selectedTx, setSelectedTx] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const universe = useUniverse();
  const inputRef = useRef<HTMLInputElement>();

  const transactions = universe.transactions;

  let total = 0;
  const str = transactions
    .filter((tx) => dayjs(tx.createdAt).isAfter(dayjs().subtract(1, 'month')))
    .filter((tx) => !tx.ignore && tx.amount > 0)
    .map((tx) => {
      const date = dayjs(tx.createdAt).format('DD/MM/YYYY');
      total += tx.amount;
      return `${date} ${tx.title} ${formatter.format(
        tx.amount / 100,
      )} (${formatter.format(total / 100)})`;
    })
    .join('\n');

  console.log(str);

  const fuse = new Fuse(transactions, {
    keys: ['title', 'tags.title'],
    includeScore: true,
    includeMatches: true,
    shouldSort: false,
  });

  const results = useMemo(() => {
    const searchResults = fuse.search(search);

    let grouped = transactions.reduce(
      (obj, tx) => {
        const day =
          tx.status === 'BOOKED'
            ? dayjs(tx.createdAt).startOf('hour').toISOString()
            : 'PENDING';

        obj[day] ??= {
          amount: 0,
          date: day,
          transactions: [],
        };

        obj[day].transactions.push(tx);

        obj[day].amount += tx.ignore ? 0 : tx.amount;

        return obj;
      },
      {} as {
        [key: string]: {
          date: string;
          amount: number;
          transactions: TransactionType[];
        };
      },
    );

    if (search) {
      grouped = searchResults
        .filter((sr) => sr.score! < 0.5)
        .reduce(
          (obj, tx) => {
            const day = dayjs(tx.item.createdAt).format('DD/MM/YYYY');

            obj[day] ??= {
              amount: 0,
              date: day,
              transactions: [],
            };

            let str: any = tx.item.title.split('');

            for (const match of tx.matches!) {
              for (const index of match.indices) {
                if (match.key === 'title') {
                  str = [
                    ...str.slice(0, index[0]),
                    <b>{str.slice(index[0], index[1] + 1)}</b>,
                    ...str.slice(index[1] + 1),
                  ];
                } else {
                }
              }
            }

            obj[day].transactions.push({
              ...tx.item,

              // @ts-ignore
              title: <span>{str}</span>,
            });

            obj[day].amount += tx.item.amount;

            return obj;
          },
          {} as {
            [key: string]: {
              date: string;
              amount: number;
              transactions: TransactionType[];
            };
          },
        );
    }

    return grouped;
  }, [search, universe.transactions]);

  const totalAmount = Object.values(results).reduce(
    (sum, group) => sum + group.amount,
    0,
  );

  const searchScrollTargetY =
    window.scrollY + (inputRef.current?.getBoundingClientRect().y || 0) - 80;

  return (
    <Wrapper>
      <SearchInput
        placeholder="Search..."
        value={search}
        ref={(ref) => (inputRef.current = ref!)}
        onFocus={() => {
          /*window.scrollTo({
            behavior: 'smooth',
            top: searchScrollTargetY,
          }); */
        }}
        onChange={(e) => setSearch(e.target.value)}
      />
      {search && (
        <CancelSearch onClick={() => setSearch('')}>
          <FaXing />
        </CancelSearch>
      )}

      {search.length > 0 && (
        <Header className="transaction">
          <Date>Total</Date>
          <TransactionTitle></TransactionTitle>
          <Price>{formatter.format(totalAmount / 100)}</Price>
        </Header>
      )}

      {Object.values(results).map((group) => (
        <DayWrapper>
          <Header>
            <Date>
              {group.date === 'PENDING'
                ? 'Pending'
                : search
                ? group.date
                : dayjs(group.date).format('DD/MM')}
            </Date>
            <Price>{formatter.format(group.amount / 100)}</Price>
          </Header>

          {group.transactions.map((tx) => (
            <Transaction
              tx={tx}
              isSelected={selectedTx === tx.id}
              setSelectedTx={setSelectedTx}
            />
          ))}
        </DayWrapper>
      ))}
    </Wrapper>
  );
};

export default React.memo(Transactions);
