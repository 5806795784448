import React from 'react';
import styled from '@emotion/styled';
import Fuse from 'fuse.js';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  Transaction as TransactionType,
  useUniverse,
} from '../../../../Components/Universe';
import TransactionOverlay from '../../../../Scenes/TransactionOverlay';
import {
  FaMoneyBill,
  FaPiggyBank,
  FaTag,
  FaToggleOff,
  FaXing,
} from 'react-icons/fa';

const Wrapper = styled.div<{
  ignored: boolean;
  touching: boolean;
  isSavings: boolean;
  isWithdrawal: boolean;
}>`
  padding: 6px 0;
  position: relative;
  text-decoration: ${(props) => (props.ignored ? 'line-through' : 'none')};
  background: rgba(0, 0, 0, ${(props) => (props.touching ? 0.1 : 0)});
  transition: all 0.2s ease-out;
  background-color: ${(props) =>
    props.isSavings
      ? 'rgba(0, 150, 0, 0.25)'
      : props.isWithdrawal
      ? 'rgba(150, 129, 0, 0.25)'
      : 'transparent'};
`;

const Row = styled.div<{
  isIncome: boolean;
}>`
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.isIncome ? '#292' : '#333')};
`;

const TransactionTitle = styled.div`
  flex: 1;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
`;

const Date = styled.div`
  font-size: 12px;
  flex: 0 0 45px;
`;

const IconWrapper = styled.div`
  padding-top: 3px;
  flex: 0 0 40px;
  font-size: 12px;
  position: relative;
  top: -3px;
  line-height: -15px;
  color: #aaa;
`;
const Price = styled.div``;

const formatter = Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

interface Props {
  tx: TransactionType;
  isSelected: boolean;
  setSelectedTx: (id: string | null) => void;
}

const Transaction: React.FC<Props> = ({ tx, setSelectedTx, isSelected }) => {
  const [touching, setTouching] = useState(false);
  const [overlayXOffset, setOverlayXOffset] = useState(0);

  const isSavings = tx.categoryId === 'SAVINGS';
  const isWithdrawal = tx.categoryId === 'WITHDRAWAL';
  const isDreamFulfillment = tx.categoryId === 'FULFILLED_DREAM';

  return (
    <Wrapper
      onTouchStart={() => setTouching(true)}
      onTouchEnd={() => setTouching(false)}
      onClick={(e) => {
        setOverlayXOffset(e.clientX);

        // Savings stahes don't have any meta actions
        if (!isSavings) {
          setSelectedTx(tx.id);
        }
      }}
      ignored={tx.ignore}
      touching={touching}
      isSavings={isSavings}
      isWithdrawal={isWithdrawal || isDreamFulfillment}
    >
      {isSelected && (
        <TransactionOverlay
          offset={overlayXOffset - 20}
          onClose={() => setSelectedTx(null)}
          tx={tx}
        />
      )}
      <Row isIncome={tx.amount < 0}>
        <Date>{dayjs(tx.createdAt).format('HH:mm')}</Date>
        <IconWrapper>
          <FaTag color="#ccc" size={12} /> {tx.tags.length}
        </IconWrapper>

        <TransactionTitle>
          {isSavings && <FaMoneyBill color="#2a2" size={12} />}
          {isDreamFulfillment && '🏆'}{' '}
          {tx.title.toLowerCase ? tx.title.toLowerCase() : tx.title}
        </TransactionTitle>
        {tx.expenseId && (
          <FaToggleOff
            style={{
              marginRight: 10,
              marginTop: 2,
            }}
            size={16}
          />
        )}
        <Price>
          {tx.amount < 0
            ? `+${formatter.format(-tx.amount / 100)}`
            : formatter.format(tx.amount / 100)}
        </Price>
      </Row>
    </Wrapper>
  );
};

export default React.memo(Transaction);
