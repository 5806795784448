import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 100vh;
  background-color: #aec6ce;
  padding: 20px;
  padding-top: 60px;
`;

export const Content = styled.div`
  padding: 20px;
  background-color: #f5f5dd;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;

export const Centre = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FadeIn = styled.div`
  animation: fadeIn 0.25s ease-in-out;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
