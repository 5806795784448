import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useUniverse } from '../../Components/Universe';
import { useAPI } from '../../Components/API';

const Wrapper = styled.div``;

const Expense = styled.div`
  font-size: 16px;
`;

const DeleteIcon = styled.div`
  background: #000;
  width: 30px;
  height: 30px;
`;

interface Props {
  initialData?: {
    id: string;
    title: string;
    date: string;
    period: string;
  };
}

const EditExpense: React.FC<Props> = ({ initialData }) => {
  const universe = useUniverse();
  const api = useAPI();

  function handleDelete(id: string) {
    // api.deleteExpense
  }

  return (
    <Wrapper>
      {universe.expenses.map((expense) => (
        <Expense key={expense.id}>
          {expense.title}
          <DeleteIcon onClick={() => handleDelete(expense.id)} />
        </Expense>
      ))}
    </Wrapper>
  );
};

export default EditExpense;
