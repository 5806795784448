import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import findSalaryDate from 'src/lib/find-salary-date';
import { findTopCategories } from 'src/lib/find-top-categories';
import { useAPI } from '../API';
import {
  percentageToHsl,
  useDayOfMonthFinance,
  useRollingFinance,
} from '../FinanceCalculator';
import { useTransactions } from '../Transactions';
import { useUniverse } from '../Universe';
import { useContext } from 'react';
import React from 'react';

interface FinanceResult {
  color: string;
  oneMonthAgoTotal: number;
  moneyQuality: number;
  moneyMarkedForSavings: number;
  recoveryDates: any;
  dailyBudget: number;
  moneyQualityFraction: number;
  healthyDailyBudget: number;
  remaining: number;
  spentThisMonth: number;
}

const FinanceContext = React.createContext<{
  rolling: FinanceResult;
  monthly: FinanceResult;
  yesterday: FinanceResult;
  insights: string | null;
  quote: { id: string; content: string } | null;
  refetch: () => void;
  // @ts-ignore
}>({
  refetch: () => {},
});

interface Props {
  children: React.ReactNode;
}

const getCachedResult: () => FinanceResult | null = () => {
  const cachedResult = localStorage.getItem('finance');

  if (!cachedResult) {
    return null;
  }

  return JSON.parse(cachedResult);
};

export const FinanceProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const cachedResult = useMemo(() => getCachedResult(), []);

  const [insights, setInsights] = useState<string | null>(null);
  const [quote, setQuote] = useState<{ id: string; content: string } | null>(
    null,
  );
  const api = useAPI();
  const universe = useUniverse();

  const monthTransactions = useTransactions({
    start: findSalaryDate(dayjs()),
    excludeExpensed: true,
    amount_gt: -3000000,
  });

  const rollingTransactions = useTransactions({
    start: dayjs().subtract(1, 'month'),
    end: dayjs(),
    excludeExpensed: true,
    amount_gt: -3000000,
  });

  const yesterdayRollingTransactions = useTransactions({
    start: dayjs().subtract(1, 'month').subtract(1, 'day'),
    end: dayjs().subtract(1, 'day'),
    excludeExpensed: true,
    amount_gt: -3000000,
  });

  const dayOfMonthResult = useDayOfMonthFinance(monthTransactions);
  const rollingMonthResult = useRollingFinance(
    universe.budget,
    rollingTransactions,
    universe.expenses,
  );

  const yesterdayRollingMonthResult = useRollingFinance(
    universe.budget,
    yesterdayRollingTransactions,
    universe.expenses,
  );
  function refetchQuote(update = true) {
    const categories = findTopCategories(rollingTransactions);

    /**
     * Regular quote
     */
    return api
      .quote(
        rollingMonthResult.moneyQuality,
        yesterdayRollingMonthResult.moneyQuality,
        Math.max(0, rollingMonthResult.oneMonthAgoTotal / 100),
        categories,
      )
      .then((res) => {
        if (update) {
          setQuote(res.quote);
        }
        return res;
      });
  }

  useEffect(() => {
    if (universe.loading) {
      return;
    }

    let ignore = false;

    refetchQuote(false).then((res) => {
      if (ignore) {
        return;
      }
      setQuote(res.quote);
    });

    return () => {
      ignore = true;
    };
  }, [rollingMonthResult.moneyQuality, universe.loading]);

  /**
   * Updates cached result
   */
  useEffect(() => {
    if (universe.loading) {
      return;
    }

    localStorage.setItem(
      'finance',
      JSON.stringify({
        ...rollingMonthResult,
        moneyQuality: rollingMonthResult.moneyQuality,
      }),
    );
  }, [rollingMonthResult]);

  const useCache = universe.loading && cachedResult;
  const value = {
    yesterday: yesterdayRollingMonthResult,
    rolling: useCache ? cachedResult! : rollingMonthResult,
    monthly: dayOfMonthResult,
    quote,
    insights,
    refetch: refetchQuote,
  };

  return (
    <FinanceContext.Provider value={value}>{children}</FinanceContext.Provider>
  );
};

export const useFinance = () => useContext(FinanceContext);
