import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { useMemo } from 'react';
import { useUniverse, Transaction } from '../Universe';

interface Props {
  start?: Date | Dayjs;
  end?: Date | Dayjs;
  amount_gt?: number;
  excludeExpensed?: boolean;
}

export const useTransactions = (options: Props) => {
  const universe = useUniverse();
  return useMemo(
    () =>
      universe.transactions.filter((tx) => {
        const date = dayjs(tx.createdAt);

        if (tx.ignore) {
          return false;
        }

        if (options.excludeExpensed && tx.expenseId) {
          return false;
        }

        if (options.amount_gt !== undefined && tx.amount < options.amount_gt) {
          return false;
        }

        if (options.start && date.isBefore(options.start)) {
          return false;
        }

        if (options.end && date.isAfter(options.end)) {
          return false;
        }

        return true;
      }),
    [
      universe.transactions,
      options.excludeExpensed,
      options.start,
      options.end,
      options.amount_gt,
    ],
  );
};

const GROUP_DATE_FORMATS = {
  MINUTE: 'mm',
  HOUR: 'HH',
  DAY: 'DD',
  WEEK: 'wwww',
  MONTH: 'MM',
};

const GROUP_VALUE_RANGES = {
  MINUTE: 60,
  HOUR: 24,
  DAY: 31,
  WEEK: 52,
  MONTH: 12,
};

export const useGroupedTransactions = (
  filter: Props,
  groupBy: 'MINUTE' | 'HOUR' | 'DAY' | 'WEEK' | 'MONTH',
  aggregate?: boolean, // Should all transactions for the same hour of the day be grouped?
  transactions?: Transaction[],
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const universeTransactions = transactions ?? useTransactions(filter);

  let initialValues: {
    [key: string]: {
      transactions: Transaction[];
      amount: number;
      formattedDate: string;
    };
  } = {};

  if (aggregate) {
    initialValues = Array.from(
      Array(GROUP_VALUE_RANGES[groupBy]).keys(),
    ).reduce(
      (obj, n) => {
        const key = n.toString().padStart(2, '0');
        obj[key] = {
          transactions: [],
          amount: 0,
          formattedDate: key,
        };
        return obj;
      },
      {} as {
        [key: string]: {
          transactions: Transaction[];
          amount: number;
          formattedDate: string;
        };
      },
    );
  }

  const grouped = (transactions ?? universeTransactions).reduce((obj, tx) => {
    const date = dayjs(tx.createdAt).startOf(
      groupBy.toLowerCase() as OpUnitType,
    );

    const formatted = date.format(GROUP_DATE_FORMATS[groupBy]);
    const key = aggregate ? formatted : date.toISOString();

    obj[key] ??= {
      transactions: [],
      amount: 0,
      formattedDate: formatted,
    };

    obj[key].transactions.push(tx);
    obj[key].amount += tx.amount;

    return obj;
  }, initialValues);

  return Object.values(grouped);
};
