import { useEffect, useState } from 'react';
import {
  FaCheck,
  FaCheckCircle,
  FaCircle,
  FaCircleNotch,
  FaRegCheckCircle,
} from 'react-icons/fa';
import { useAPI } from 'src/Components/API';
import Button from 'src/Components/Button';
import styled from '@emotion/styled';
import { useUniverse } from '../../Components/Universe';
import findBudget from '../../lib/find-budget';
import { useNavigation } from 'src/Components/Navigation';
import { Wrapper } from 'src/Components/Layout';

const Content = styled.div`
  padding: 20px;
  position: relative;
  background-color: #f5f5dd;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;
const Subtitle = styled.div`
  font-size: 16px;
  opacity: 0.5;
  padding: 10px 30px;
  margin-bottom: 20px;
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const Value = styled.div`
  font-size: 23px;
  flex: 0 0 150px;
  font-weight: 300;
`;

const ValueInput = styled.input`
  font-size: 23px;
  font-weight: 300;
  width: 100px;
  font-size: 15px;
  padding: 8px;
  flex: 0 0 120px;
  margin-right: 30px;
  border: 0;
  border-bottom: 2px solid #ccc;
  background: #fafafa;
`;

const ValueText = styled.div`
  font-size: 14px;
`;

const Checkmark = styled.div`
  flex: 0 0 50px;
`;

const GoBackButton = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-decoration: underline;
  cursor: pointer;
  color: #38a;
`;

const BButton = styled.div`
  margin: 50px auto;
  margin-bottom: 0;
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background: #38a;
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const BudgetText = styled.div`
  font-size: 12px;
`;

const formatter = Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

const SetBudget = () => {
  const [custom, setCustom] = useState('');
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState(0);
  const universe = useUniverse();
  const navigator = useNavigation();
  const api = useAPI();
  const salary = Math.round(
    findBudget(universe.transactions, 'largest-income') / 100,
  );
  const averageSpend = Math.round(
    findBudget(universe.transactions, 'average') / 100,
  );

  const budget = [salary, averageSpend, Number(custom)][selection] as number;

  /**
   * Sometimes we dont have all the data we need to calculate the budget.
   *
   * This ensures  we stay fresh
   */
  useEffect(() => {
    if (universe.loading) {
      return;
    }

    const id = setTimeout(() => {
      universe.refetch();
    }, 3000);

    return () => clearTimeout(id);
  }, [universe]);

  async function handleConfirm() {
    setLoading(true);
    await api.updateUserSettings({ budget: budget * 100 });
    window.location.reload();
  }

  return (
    <Wrapper>
      <Content>
        <Title>Time to set your budget!</Title>
        <Subtitle>
          Your budget is in essence your income, before moving any money to
          savings or investments
        </Subtitle>

        {universe.transactions.length ? (
          <>
            <Subtitle>
              We have estimated two different budgets based on your transaction
              history. Pick one, or enter one yourself
            </Subtitle>

            <Row onClick={() => setSelection(0)}>
              <Checkmark>
                {selection === 0 ? (
                  <FaCheckCircle color="#3a3" />
                ) : (
                  <FaRegCheckCircle />
                )}
              </Checkmark>
              <Value>{formatter.format(salary)}</Value>
              <ValueText>Your largest income</ValueText>
            </Row>
            <Row onClick={() => setSelection(1)}>
              <Checkmark>
                {selection === 1 ? (
                  <FaCheckCircle color="#3a3" />
                ) : (
                  <FaRegCheckCircle />
                )}
              </Checkmark>
              <Value>{formatter.format(averageSpend)}</Value>
              <ValueText>Your average spend in a month</ValueText>
            </Row>
            <Row onClick={() => setSelection(2)}>
              <Checkmark>
                {selection === 2 ? (
                  <FaCheckCircle color="#3a3" />
                ) : (
                  <FaRegCheckCircle />
                )}
              </Checkmark>
              <ValueInput
                value={custom}
                type="number"
                inputMode="numeric"
                placeholder="Enter value here"
                onChange={(e) => setCustom(e.target.value)}
              />
              <ValueText>Custom amount</ValueText>
            </Row>

            <Button disabled={loading} onClick={handleConfirm}>
              <b>{loading ? 'SAVING...' : 'CONFIRM'}</b>
              <br />
              <BudgetText>{formatter.format(budget)}</BudgetText>
            </Button>
          </>
        ) : (
          <Subtitle>
            Loading
            <br />
            <br />
            Your account is being set up, this should only take a few seconds
          </Subtitle>
        )}

        {universe.transactions.length === 0 && (
          <GoBackButton
            onClick={() => {
              navigator.setRoute('connect');
            }}
          >
            Go back
          </GoBackButton>
        )}
      </Content>
    </Wrapper>
  );
};

export default SetBudget;
