import React from 'react';

const Context = React.createContext({
  route: '',
  setRoute: (route: string) => {},
});

interface Props {
  children: React.ReactNode;
}

export const NavigationProvider: React.FC<Props> = ({ children }) => {
  const [route, setRoute] = React.useState(
    localStorage.getItem('onboarded') ? 'home' : 'onboarding',
  );

  return (
    <Context.Provider value={{ route, setRoute }}>{children}</Context.Provider>
  );
};

export const useNavigation = () => React.useContext(Context);
