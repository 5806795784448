import React, { useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useAPI } from 'src/Components/API';
import Button from 'src/Components/Button';
import { useNavigation } from 'src/Components/Navigation';
import styled from '@emotion/styled';
import { BankItem } from '../Connect';

const Wrapper = styled.div`
  padding-top: 60px;
`;

const Account = styled.div<{
  logo?: string;
}>`
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #ccc;
  display: flex;

  font-size: 20px;
  color: #333;
  font-weight: bold;

  background: url('${(props) => props.logo}');
  background-size: cover;
  background-position: center center;

  &:last-child {
    border: 0;
  }
`;

const Title = styled.div`
  font-size: 18px;
`;

const RemoveIcon = styled.div`
  position: absolute;
  top: 5px;
  right: -5px;
  padding: 20px;
`;

const Logo = styled.img`
  width: 50px;
`;

const formatter = Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

const Accounts: React.FC = () => {
  const { setRoute } = useNavigation();
  const [banks, setBanks] = useState<BankItem[]>([]);

  const api = useAPI();
  const [accounts, setAccounts] = useState([
    {
      id: '123',
      institution_id: '',
      name: 'Loading...',
      balance: { amount: 0 },
    },
  ]);

  useEffect(() => {
    api.banks('SE').then((response) => setBanks(response));
  }, []);

  useEffect(() => {
    api.accounts().then((res: any) => {
      if (!res) {
        return;
      }

      const flat: any[] = Object.values(res.flat()[0]);

      console.debug(flat);
      setAccounts(flat.filter((a) => a.resourceId));
    });
  }, []);

  return (
    <Wrapper>
      {accounts.map((account) => (
        <Account>
          <div>
            <Title>{account.name}</Title>
            {formatter.format(account.balance.amount)}
          </div>
          <RemoveIcon
            onClick={() => {
              api.deleteAccount(account.id);
            }}
          >
            <FaTrashAlt color="#00000088" />
          </RemoveIcon>
        </Account>
      ))}

      <Button
        onClick={() => {
          console.debug('hello');
          setRoute('connect');
        }}
      >
        Add Account
      </Button>
    </Wrapper>
  );
};

export default Accounts;
