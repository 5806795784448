import { useState } from 'react';
import {
  FaCog,
  FaPiggyBank,
  FaQuestionCircle,
  FaRecycle,
  FaSignOutAlt,
} from 'react-icons/fa';
import { TiMessages } from 'react-icons/ti';
import styled from '@emotion/styled';
import { useUniverse } from '../../Components/Universe';

const Wrapper = styled.div<{
  visible: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
`;

const Background = styled.div<{
  visible: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, ${(props) => (props.visible ? 0.6 : 0)});
  transition: all 0.2s ease-out;
`;

const Content = styled.div<{
  visible: boolean;
}>`
  background-color: rgba(237, 235, 231);
  width: 250px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.15s ease-out;
  transform: translateX(${(props) => (props.visible ? 0 : 250)}px);
`;

const Row = styled.div<{
  bottom?: boolean;
}>`
  padding: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;

  & > :first-child {
    margin-right: 10px;
  }
`;

interface Props {
  onClose: () => void;
  setRoute: (route: string) => any;
  visible: boolean;
}

const Sidebar: React.FC<Props> = ({ onClose, visible, setRoute }) => {
  const [session, setSession] = useState(localStorage.getItem('token'));

  const universe = useUniverse();
  const [loading, setLoading] = useState(false);

  function goToTinkLink() {
    window.location.href =
      'https://link.tink.com/1.0/authorize/?client_id=e138d293a7114ac99c23f9fdc1857b5d&redirect_uri=https%3A%2F%2Fy4exypfwd4nafmopc5gqgzcejy0rqtqy.lambda-url.eu-north-1.on.aws%2F&market=SE&locale=en_US&scope=accounts:read,investments:read,transactions:read,user:read,identity:read&input_username=199304020770';
  }

  function refreshTransactions() {
    setLoading(true);
    fetch(
      'https://3q2srzibv6lakuwdw74qlhn42u0bxjfm.lambda-url.eu-north-1.on.aws/',
      {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({}),
        headers: new Headers({
          'x-session-key': session!,
        }),
      },
    )
      .then((d) => d.json())
      .finally(() => setLoading(false));
  }

  return (
    <Wrapper visible={visible}>
      <Background onTouchStart={onClose} visible={visible} />
      <Content onTouchStart={(e) => e.stopPropagation()} visible={visible}>
        {loading && <h3>Loading...</h3>}

        <Row>
          <h3>{universe.user?.name}</h3>
        </Row>
        <Row />
        <Row
          onClick={() => {
            setRoute('accounts');
            onClose();
          }}
        >
          <FaPiggyBank />
          Accounts
        </Row>
        <Row
          onClick={() => {
            setRoute('settings');
            onClose();
          }}
        >
          <FaCog /> Settings
        </Row>
        <Row>
          <TiMessages /> Contact
        </Row>
        <Row
          onClick={() => {
            setRoute('onboarding');
            onClose();
          }}
        >
          <FaQuestionCircle />
          Help
        </Row>

        <Row
          bottom
          onClick={() => {
            localStorage.removeItem('token');
            window.location.reload();
          }}
        >
          <FaSignOutAlt /> Log out
        </Row>
      </Content>
    </Wrapper>
  );
};

export default Sidebar;
