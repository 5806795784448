import styled from '@emotion/styled';
import { useEffect } from 'react';
import Transactions from './Components/Transactions';
import { useAPI } from '../../Components/API';
import QualityNumber from './Components/QualityNumber';
import { useFinance } from 'src/Components/FinanceProvider';
import { FadeIn } from 'src/Components/Layout';
import useStasher from 'src/Components/FinanceCalculator/useStasher';

const Wrapper = styled.div`
  padding-top: 5px;
`;

const NumberWrapper = styled.div`
  margin: 20px 0;
`;

export const Quality = styled.div`
  font-size: 56px;
  text-align: center;
  margin: 2px 80px;
  background: #6ee16e;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2;
`;

const SmallQualityWrapper = styled.div`
  display: flex;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
  margin: 2px 100px;
`;

const SmallQuality = styled.div`
  font-size: 26px;
  text-align: center;
  background: #6ee16e;
  position: relative;
  z-index: 2;
  flex: 1;
`;

const Moneybox = styled.div`
  padding: 10px;
  position: relative;
  display: flex;
  margin: 10px;
  justify-content: space-evenly;
  background: #f6fff6;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
`;

const Segment = styled.div`
  margin: 10px;
`;

const Title = styled.div`
  font-size: 15px;
  opacity: 0.8;
`;

const Value = styled.div`
  font-size: 18px;
`;

export const Quote = styled.div`
  margin: 20px;
  padding: 20px;
  font-weight: 300;
  font-size: 15px;
  white-space: pre-wrap;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 12%);
  background: #fffbc7;
`;

const Actions = styled.div`
  margin: 10px;
`;

const ActionBlock = styled.div`
  margin: 10px;
  cursor: pointer;
`;

const ActionTitle = styled.div``;

const ActionDescription = styled.div`
  font-weight: 300;
`;

const Toggle = styled.div<{
  selected: boolean;
}>`
  font-weight: ${(props) => (props.selected ? 'bold' : 400)};
`;

const QualityIntro = styled.div`
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 3px;
  text-align: center;
`;

const BudgetBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px;
  padding: 0 20px;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  justify-content: space-evenly;
  margin-bottom: 25px;
`;

const WhatNowBox = styled.div`
  margin: 20px;
  padding: 10px;
  font-weight: 300;
  font-size: 15px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
`;

const WhatNowTitle = styled.div`
  font-size: 1px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const WhatNowSubtitle = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const BudgetTitle = styled.div`
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;

  & > * {
    flex: 1.5;
  }
`;

const ScoreToBeat = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`;

const Information = styled.div`
  padding: 20px;
  margin: 20px;
  border: 1px solid #34a7e741;
  background: #0098ff1f;
  border-radius: 3px;
`;

const Alert = styled.div``;

interface Props {
  handleOpenStories: () => void;
}

const Home: React.FC<Props> = ({ handleOpenStories }) => {
  const api = useAPI();
  const stasher = useStasher();
  const finance = useFinance();

  const diff = finance.rolling.moneyQuality - finance.yesterday.moneyQuality;

  const scoreToBeat = Math.round(finance.rolling.oneMonthAgoTotal / 100) * 100;

  useEffect(() => {
    // Mark quote as "seen" if the user has looked at it for more than 0.7s
    const id = setTimeout(() => {
      if (!finance.quote) {
        return;
      }

      api.markQuoteSeen(finance.quote?.id);
    }, 700);

    return () => {
      clearTimeout(id);
    };
  }, [finance.quote]);

  return (
    <Wrapper>
      <NumberWrapper>
        <QualityIntro>Today's score:</QualityIntro>
        <QualityNumber
          onClick={() => {
            handleOpenStories();
          }}
          number={finance.rolling.moneyQuality}
          color={finance.rolling.color}
          diff={diff}
        />

        <Quote>
          {finance.quote && <FadeIn>{finance.quote?.content.trim()}</FadeIn>}
        </Quote>
      </NumberWrapper>

      {/* {stasher.moneyToAllocate > 10000 && (
        <WhatNowBox>
          <WhatNowTitle>What now?</WhatNowTitle>
          <WhatNowSubtitle>
            💰 You have {stasher.moneyToAllocate / 100} kr to allocate to your
            goals
          </WhatNowSubtitle>
        </WhatNowBox>
      )}
 */}
      {finance.rolling.moneyQuality < 100 && (
        <>
          <Information>
            <BudgetTitle>
              <div>
                Your score is a little low, spend less than what you spent a
                month ago to increase it
              </div>
              <ScoreToBeat>{scoreToBeat / 100} kr</ScoreToBeat>
            </BudgetTitle>
          </Information>
          {/*<Information>
            <BudgetTitle>
              <div>
                What you can afford to spend, based on how much you have spent
                from your current salary
              </div>
              <ScoreToBeat>{dayOfMonthResult.dailyBudget / 100} kr</ScoreToBeat>
            </BudgetTitle>
      </Information>*/}
        </>
      )}

      <Transactions />
    </Wrapper>
  );
};

export default Home;
