import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { datadogLogs } from '@datadog/browser-logs';
import { APIProvider } from './Components/API';
import { UniverseProvider } from './Components/Universe';
import { FinanceProvider } from './Components/FinanceProvider';
import { NavigationProvider } from './Components/Navigation';

// Add __DEV__ to global namespace
declare global {
  interface Window {
    __DEV__: boolean;
    hasNativeWrapper: boolean;
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
  }
}

window.__DEV__ = process.env.NODE_ENV !== 'production';
window.hasNativeWrapper = !!window.ReactNativeWebView;

datadogLogs.init({
  clientToken: 'pub2f8a8f7863ad32077d28692b87bb6157',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <NavigationProvider>
      <APIProvider>
        <UniverseProvider>
          <FinanceProvider>
            <App />
          </FinanceProvider>
        </UniverseProvider>
      </APIProvider>
    </NavigationProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
