import PullToRefresh from 'react-simple-pull-to-refresh';
import ReactGA from 'react-ga4';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Navbar from './Components/Navbar';
import Home from './Scenes/Home';
import Insights from './Scenes/Insights';
import Savings from './Scenes/Savings';
import Expenses from './Scenes/Expenses';
import WhatsMyBudget from './Scenes/WhatsMyBudget';
import CreateExpense from './Scenes/Expenses/Edit';
import Settings from './Scenes/Settings';
import Accounts from './Scenes/Accounts';
import SetBudget from './Scenes/SetBudget';
import LoginRegister from './Scenes/LoginRegister';
import { useAPI } from './Components/API';
import { useUniverse } from './Components/Universe';
import CreateDream from './Scenes/Dream/create';
import CreateSavings from './Scenes/Savings/create';
import Sidebar from './Scenes/Sidebar';
import { TiThMenu } from 'react-icons/ti';
import Authenticate from './Scenes/Authenticate';
import Onboarding from './Scenes/Onboarding';
import Connect from './Scenes/Connect';
import { useNavigation } from './Components/Navigation';
import Stories from './Scenes/Home/Components/Stories';
import Dreams from './Scenes/Dream';
import useBridge from './Components/Bridge';
import { useLogger } from './Components/Logger';
import MessageHandler from './Components/MessageHandler';

const Wrapper = styled.div`
  max-width: 550px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  padding-top: 50px;
  background-color: #aec6ce;
`;

const Content = styled.div`
  margin: 20px;
  background-color: #f5f5dd;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
  backdrop-filter: blur(1.5px);
  background-color: rgba(255, 255, 255, 0.4);
  mask: linear-gradient(black 20%, transparent);
  height: 110px;
  z-index: 3;
  font-size: 32px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;

  h3 {
    font-size: 20px;
  }
`;

const Logo = styled.div`
  height: 100%;
  background: url('http://get.imglarger.com:8889/results/RyumZMvS_4x.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 200px;
  margin: 0 auto;
`;

const Refresh = styled.div`
  position: absolute;
  top: calc(5px + env(safe-area-inset-bottom, 20px));

  right: 19px;
`;

function App() {
  const logger = useLogger('app');
  const bridge = useBridge();
  // State for toggling story visibility
  const [showStory, setShowStory] = React.useState(false);

  const { route, setRoute } = useNavigation();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [session, setSession] = useState(localStorage.getItem('token'));
  const universe = useUniverse();

  // localStorage.setItem('token', 'e44c9f37-6bbb-4730-81e0-4c9770615bd4');

  useEffect(() => {
    logger.info('Booting');

    bridge.boot();

    if (window.__DEV__) {
      return;
    }

    ReactGA.initialize('G-8R3J3WTXE1');
  }, []);

  useEffect(() => {
    if (window.__DEV__) {
      return;
    }

    document.title = route;

    ReactGA.send({ hitType: 'pageview', page: `/${route}` });
  }, [route]);

  if (!universe.user) {
    return <LoginRegister />;
  }

  if (
    (!universe.loading &&
      universe.isLoggedIn &&
      !universe.hasActiveToken &&
      universe.transactions.length === 0) ||
    route === 'connect'
  ) {
    return <Connect showCloseButton={route === 'connect'} />;
  }

  if (!universe.loading && !universe.budget) {
    return (
      <PullToRefresh
        isPullable
        onRefresh={async () => window.location.reload()}
      >
        <SetBudget />
      </PullToRefresh>
    );
  }

  if (route === 'onboarding') {
    return (
      <Onboarding
        onFinished={() => {
          localStorage.setItem('onboarded', 'true');
          setRoute('home');
        }}
      />
    );
  }

  return (
    <Wrapper>
      <Stories showStory={showStory} setShowStory={setShowStory} />

      <Header>
        <Logo />
        <Refresh
          onClick={() => {
            setSidebarVisible(true);
          }}
        >
          <TiThMenu />
        </Refresh>
      </Header>

      <>
        <PullToRefresh
          isPullable={['home'].includes(route)}
          onRefresh={async () => window.location.reload()}
        >
          <Content>
            {route === 'home' && (
              <Home handleOpenStories={() => setShowStory(true)} />
            )}
            {route === 'insights' && <Insights />}
            {route === 'wmb' && <WhatsMyBudget />}
            {route === 'accounts' && <Accounts />}
            {route === 'authenticate' && <Authenticate />}
            {route === 'savings' && <Savings />}
            {route === 'expenses' && <Expenses />}
            {route === 'dreams' && <Dreams />}
            {route === 'create-expense' && <CreateExpense />}
            {route === 'create-dream' && <CreateDream />}
            {route === 'create-savings' && <CreateSavings />}
            {route === 'settings' && <Settings />}
          </Content>
        </PullToRefresh>

        <Navbar
          currentRoute={route}
          onRouteChange={(route) => setRoute(route)}
        />
      </>

      <Sidebar
        visible={sidebarVisible}
        setRoute={setRoute}
        onClose={() => setSidebarVisible(false)}
      />
    </Wrapper>
  );
}

export default () => (
  <>
    <MessageHandler />
    <App />
  </>
);
