import dayjs from 'dayjs';
import findSalaryDate from 'src/lib/find-salary-date';
import { useGroupedTransactions } from '../Transactions';
import { Expense, Transaction, useUniverse } from '../Universe';

export function percentageToHsl(
  percentage: number,
  hue0: number,
  hue1: number,
) {
  var hue = percentage * (hue1 - hue0) + hue0;
  return 'hsl(' + hue + ', 100%, 50%)';
}

export const useRollingFinance = (
  userBudget: number,
  transactions: Transaction[],
  expenses: Expense[],
  now = dayjs(),
) => {
  const grouped = useGroupedTransactions(
    {},
    'DAY',
    true,
    transactions.filter((tx) => {
      return dayjs().startOf('day').isAfter(dayjs(tx.createdAt));
    }),
  ).sort((a, b) => (a.formattedDate > b.formattedDate ? 1 : -1));
  const DAYS_OF_EASING = 1;

  const lastDays = Array.from(new Array(DAYS_OF_EASING))
    .map((_, i) => {
      const date = dayjs().add(i, 'day');
      const key = date.format('DD');

      return grouped.find((g) => g.formattedDate === key)!;
    })
    .filter(Boolean);

  const sumOfLastDays = lastDays.reduce(
    (sum, payment) => sum + payment.amount,
    0,
  );

  let oneMonthAgoTotal = sumOfLastDays / DAYS_OF_EASING;
  oneMonthAgoTotal = Math.round(oneMonthAgoTotal);

  const transactionTotal = transactions.reduce(
    (sum, payment) => sum + payment.amount,
    0,
  );

  const expenseSum = expenses.reduce((sum, e) => sum + e.amount, 0);

  const spentThisMonth = Math.round(transactionTotal);
  const budget = userBudget - expenseSum;

  const remaining = budget - spentThisMonth;
  const dailyBudget = Math.round(remaining / 30);
  const healthyDailyBudget = Math.round(budget / 30);

  const targetAtEnd = budget * 0.1;

  const currentTargetRelativeToDayInMonth =
    (spentThisMonth / targetAtEnd) * 100;

  const moneyMarkedForSavings = Math.round(
    remaining - currentTargetRelativeToDayInMonth,
  );

  const recoveryDates = [...transactions].reverse().reduce(
    (data, tx) => {
      const days = dayjs(tx.createdAt).diff(now.subtract(30, 'days'), 'days');

      // New budget = what we have today, plus all the transactions we are "skipping",
      // plus an arbitrary assumed spend of half of our regular budget per day
      const newBudget = remaining + data.sum - (healthyDailyBudget / 2) * days;
      const newBudgetPerday = Math.round(newBudget / 30);

      // if we have negative remaining budget = if we are above our budget
      // and we havent found the date yet, check if we are there now
      if (!data.backToZero.length) {
        if (newBudgetPerday > 0) {
          data.backToZero = dayjs(tx.createdAt).add(30, 'days').format('DD/MM');
        }
      }

      // if we have a less-than-optimal remaining budget = our daily budget is down compared to start of month
      // and we havent found the date yet, check if we are there now
      if (!data.backToHealthy.length) {
        if (newBudgetPerday >= healthyDailyBudget) {
          data.backToHealthy = dayjs(tx.createdAt)
            .add(30, 'days')
            .format('DD/MM');
        }
      }

      data.sum += tx.amount;
      return data;
    },
    {
      sum: 0,
      backToZero: '',
      backToHealthy: '',
    },
  );

  const spentFraction = spentThisMonth / budget;
  // This function is an estimation of the graph that "feels right"

  const moneyQualityFraction = 2.5474 - spentFraction * 1.7013;
  const moneyQuality = Math.round(1000 * moneyQualityFraction);

  const color = percentageToHsl(
    Math.min(1, Math.max(0, moneyQualityFraction) ** 4),
    0,
    120,
  );

  return {
    color,
    oneMonthAgoTotal,
    moneyQuality,
    moneyMarkedForSavings,
    recoveryDates,
    dailyBudget,
    moneyQualityFraction,
    healthyDailyBudget,
    remaining,
    spentThisMonth,
  };
};

export const calculateRolling = useRollingFinance;

export const useDayOfMonthFinance = (transactions: Transaction[]) => {
  const universe = useUniverse();

  const startDate = findSalaryDate(dayjs());
  const daysInMonth = startDate.add(1, 'month').diff(startDate, 'days');
  const dayOfMonth = dayjs().diff(startDate, 'days');

  const daysRemaining = startDate.add(1, 'month').diff(dayjs(), 'days');

  const transactionTotal = transactions.reduce(
    (sum, payment) => sum + payment.amount,
    0,
  );

  const expenseSum = universe.expenses.reduce((sum, e) => sum + e.amount, 0);

  const spentThisMonth = Math.round(transactionTotal);
  const budget = 4200000 - expenseSum;

  const remaining = budget * 0.9 - spentThisMonth;
  const dailyBudget = Math.round(remaining / daysRemaining);
  const healthyDailyBudget = Math.round(budget / daysInMonth);
  const targetAtEnd = budget * 0.1;

  const currentTargetRelativeToDayInMonth =
    (spentThisMonth / (budget * 0.9 * (dayOfMonth / daysInMonth))) * 100;

  const moneyMarkedForSavings = Math.round(
    remaining - currentTargetRelativeToDayInMonth,
  );

  const recoveryDates = {
    sum: 0,
    backToZero: '',
    backToHealthy: '',
  };

  const moneyQualityFraction = dailyBudget / healthyDailyBudget;
  const moneyQuality = Math.round(1000 * moneyQualityFraction);

  const color = percentageToHsl(
    Math.min(1, Math.max(0, moneyQualityFraction) ** 2),
    0,
    120,
  );

  return {
    color,
    oneMonthAgoTotal: 0,
    moneyQuality,
    moneyMarkedForSavings,
    recoveryDates,
    dailyBudget,
    spentThisMonth,
    moneyQualityFraction,
    remaining,
    healthyDailyBudget,
  };
};

export const useSpreadFinance = (transactions: Transaction[]) => {
  const universe = useUniverse();

  const spreadTransactions = transactions
    .reduce((arr, tx) => {
      Array.from(Array(14).keys()).forEach((n, index) => {
        arr.push({
          ...tx,
          amount: Math.round(tx.amount / 14),
          createdAt: dayjs(tx.createdAt).add(index, 'days').toISOString(),
          title: `${tx.title}-${index}`,
        });
      });

      return arr;
    }, [] as Transaction[])
    .filter((t) => dayjs(t.createdAt).isAfter(dayjs().subtract(30, 'days')))
    .filter((t) => dayjs(t.createdAt).isBefore(dayjs()));

  const transactionTotal = spreadTransactions.reduce(
    (sum, payment) => sum + payment.amount,
    0,
  );

  const oneMonthAgoTotal = spreadTransactions
    .filter((t) => dayjs(t.createdAt).isBefore(dayjs().subtract(29, 'days')))
    .filter((t) => dayjs(t.createdAt).isAfter(dayjs().subtract(30, 'days')))
    .reduce((sum, payment) => sum + payment.amount, 0);

  const expenseSum = universe.expenses.reduce((sum, e) => sum + e.amount, 0);

  const spentThisMonth = Math.round(transactionTotal);
  const budget = 4200000 - expenseSum;

  const remaining = budget - spentThisMonth;
  const dailyBudget = Math.round(remaining / 30);
  const healthyDailyBudget = Math.round(budget / 30);

  const targetAtEnd = budget * 0.1;

  const currentTargetRelativeToDayInMonth =
    (spentThisMonth / targetAtEnd) * 100;

  const moneyMarkedForSavings = Math.round(
    remaining - currentTargetRelativeToDayInMonth,
  );

  const recoveryDates = [...spreadTransactions].reverse().reduce(
    (data, tx) => {
      const days = dayjs(tx.createdAt).diff(
        dayjs().subtract(30, 'days'),
        'days',
      );

      // New budget = what we have today, plus all the transactions we are "skipping",
      // plus an arbitrary assumed spend of half of our regular budget per day
      const newBudget = remaining + data.sum - (healthyDailyBudget / 2) * days;
      const newBudgetPerday = Math.round(newBudget / 30);

      // if we have negative remaining budget = if we are above our budget
      // and we havent found the date yet, check if we are there now
      if (!data.backToZero.length) {
        if (newBudgetPerday > 0) {
          data.backToZero = dayjs(tx.createdAt).add(30, 'days').format('DD/MM');
        }
      }

      // if we have a less-than-optimal remaining budget = our daily budget is down compared to start of month
      // and we havent found the date yet, check if we are there now
      if (!data.backToHealthy.length) {
        if (newBudgetPerday >= healthyDailyBudget) {
          data.backToHealthy = dayjs(tx.createdAt)
            .add(30, 'days')
            .format('DD/MM');
        }
      }

      data.sum += tx.amount;
      return data;
    },
    {
      sum: 0,
      backToZero: '',
      backToHealthy: '',
    },
  );

  const moneyQualityFraction = remaining / targetAtEnd;
  const moneyQuality = Math.round(1000 * moneyQualityFraction);

  const color = percentageToHsl(Math.min(1, moneyQualityFraction ** 2), 0, 120);

  return {
    color,
    oneMonthAgoTotal,
    moneyQuality,
    moneyMarkedForSavings,
    recoveryDates,
    dailyBudget,
    moneyQualityFraction,
    healthyDailyBudget,
    remaining,
    spentThisMonth,
  };
};
