// APP COMPONENT
// Upon rendering of App component, make a request to create and
// obtain a link token to be used in the Link component
import React, { useEffect, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
const Authenticate = () => {
  const [linkToken, setLinkToken] = useState<string | null>(
    localStorage.getItem('link-token'),
  );

  const search = new URLSearchParams(window.location.search);
  const stateId = search.get('oauth_state_id');

  useEffect(() => {
    if (stateId) {
      return;
    }

    fetch('https://localhost:8080')
      .then((d) => d.text())
      .then((abc) => {
        localStorage.setItem('link-token', abc);
        setLinkToken(abc);
      });
  }, []);

  return linkToken != null ? <Link linkToken={linkToken} /> : <></>;
};
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link
interface LinkProps {
  linkToken: string | null;
}
const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const search = new URLSearchParams(window.location.search);
  const stateId = search.get('oauth_state_id');

  const config: PlaidLinkOptions = {
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: props.linkToken,
    oauthRedirectUri: 'http://localhost:3000',
    receivedRedirectUri: stateId ? window.location.href : undefined,
    env: 'development',
  };
  const { open, exit, ready } = usePlaidLink(config);

  return (
    <button onClick={() => open()} disabled={!ready}>
      Link account
    </button>
  );
};
export default Authenticate;
