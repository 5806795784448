import styled from '@emotion/styled';

export const FormField = styled.div`
  margin: 10px 0;
`;

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 6px;
`;

export const Toggle = styled.div`
  background: #000;
  width: 50px;
  height: 50px;
`;

export const Input = styled.input<{
  invalid?: boolean;
}>`
  border: 1px solid ${(props) => (props.invalid ? '#f00' : '#ccc')};
  box-shadow: 0;
  outline: 0;
  padding: 8px;
  width: 100%;
  font-size: 18px;
  border-radius: 2px;
  margin-bottom: 7px;
`;

export const Row = styled.div<{
  green?: boolean;
}>`
  background: ${(props) => (props.green ? '#aea' : 'transparent')};
  display: flex;
  padding: 20px;
  justify-content: space-between;
`;

export const SubmitButton = styled.div`
  padding: 10px;
  background: #e3f7ab;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  border-radius: 2px;
`;

export const ToggleButton = styled.div<{
  selected: boolean;
}>`
  width: 30px;
  height: 30px;
  background: ${(props) => (props.selected ? '#fff' : '#262626')};
  border: 8px solid #262626;
  margin-right: 15px;
  margin-top: 15px;
`;
