import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useAPI } from '../../Components/API';
import Button from 'src/Components/Button';
import { useUniverse } from 'src/Components/Universe';
import { FormField, Title, Input, Row, ToggleButton } from 'src/Styles/Form';

const Wrapper = styled.div`
  margin: 20px;
  padding: 20px 0;
`;

const HelpText = styled.div`
  font-size: 14px;
  opacity: 0.8;
`;

interface Props {
  initialData?: {
    id: string;
    title: string;
    date: string;
    period: string;
  };
}

const CreateDream: React.FC<Props> = ({ initialData }) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(initialData?.title ?? '');
  const [amount, setAmount] = useState<null | number>(null);
  const [date, setDate] = useState<Date>(dayjs().add(3, 'months').toDate());
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [urgency, setUrgency] = useState<'FLEXIBLE' | 'FIRM' | null>(null);

  const universe = useUniverse();
  const api = useAPI();

  async function handleSubmit() {
    const errors = [];

    if (!amount) {
      errors.push('amount');
    }

    if (!title) {
      errors.push('title');
    }

    console.debug(errors);

    setInvalidFields(errors);

    if (errors.length) {
      return;
    }

    setLoading(true);

    try {
      if (initialData?.id) {
      } else {
        await api.createDream({
          title,
          amount: amount!,
          deadline:
            urgency === 'FLEXIBLE' ? null : dayjs(date).format('YYYY-MM-DD'),
        });

        await universe.refetch();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const valid = invalidFields.length === 0 && amount && title && urgency;

  return (
    <Wrapper>
      <FormField>
        <Title>Title</Title>
        <Input
          value={title}
          invalid={invalidFields.includes('title')}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormField>
      <FormField>
        <Title>Amount</Title>
        <Input
          type="number"
          inputMode="numeric"
          invalid={invalidFields.includes('amount')}
          value={amount || ''}
          onChange={(e) => setAmount(Number(e.target.value))}
        />
      </FormField>

      <FormField>
        <Title>When should this dream become real?</Title>
        <Row green onTouchStart={() => setUrgency('FLEXIBLE')}>
          <div>
            <ToggleButton selected={urgency === 'FLEXIBLE'} />
          </div>
          <div>
            <Title>✨When I can afford it✨</Title>
            <HelpText>
              You dont really care when the dream is fulfilled. It will happen
              when you've saved up enough money, whenever that is.
            </HelpText>
          </div>
        </Row>
      </FormField>
      <FormField>
        <Row onTouchStart={() => setUrgency('FIRM')}>
          <div>
            <ToggleButton selected={urgency === 'FIRM'} />
          </div>
          <div>
            <Title>Specific date</Title>
            <HelpText>
              The dream HAS to happen by a specific date. Useful for things like
              a vacation or a big incoming cost.
            </HelpText>
          </div>
        </Row>
      </FormField>
      {urgency === 'FIRM' && (
        <FormField>
          <Title>Date</Title>
          <Input
            type="date"
            min={dayjs().add(2, 'days').format('YYYY-MM-DD')}
            value={dayjs(date).format('YYYY-MM-DD')}
            onChange={(e) => setDate(new Date(e.target.value))}
          />
        </FormField>
      )}
      {/* <FormField>
        <Row onClick={() => setUrgency('FLEXIBLE')}>
          <div>
            <ToggleButton selected={urgency === 'FLEXIBLE'} />
          </div>
          <div>
            <Title>Flexible</Title>
            <HelpText>
              You dont really care when the dream is fulfilled. It will happen
              when you've saved up enough money, whenever that is.
            </HelpText>
          </div>
        </Row>
      </FormField>
      <FormField>
        <Row onClick={() => setUrgency('FIRM')}>
          <div>
            <ToggleButton selected={urgency === 'FIRM'} />
          </div>
          <div>
            <Title>Firm</Title>
            <HelpText>
              The dream HAS to happen by a specific date. Useful if you're going
              on a trip with others, or saving for a wedding.
            </HelpText>
          </div>
        </Row>
      </FormField>

      <FormField>
        <Title>Importance</Title>
        <HelpText>
          How important is this dream to you? The higher the importance, the
          more money will be allocated to the dream in relation to others.
        </HelpText>
        <select
          onChange={(e) => setImportance(Number(e.target.value))}
          value={importance}
        >
          <option value={5}>5/5 Crucial </option>
          <option value={4}>4/5 Significant</option>
          <option value={3}>3/5 Modest</option>
          <option value={2}>2/5 Nice to have</option>
          <option value={1}>1/5 Irrelevant</option>
        </select>
      </FormField>*/}

      <Button disabled={!valid || loading} onClick={handleSubmit}>
        Create
      </Button>
    </Wrapper>
  );
};

export default CreateDream;
