import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { calculateRolling } from 'src/Components/FinanceCalculator';
import { useTransactions } from 'src/Components/Transactions';
import styled from '@emotion/styled';
import throttle from 'lodash/throttle';
import { Quality } from '../Home';
import { Transaction, useUniverse } from 'src/Components/Universe';

const Wrapper = styled.div`
  margin: 20px;
  padding: 20px 0;
`;

const Title = styled.div`
  font-size: 22px;
  text-align: center;
`;

const Subtitle = styled.div`
  font-size: 16px;
  opacity: 0.7;
  margin-bottom: 20px;
  text-align: center;
`;

const RangeSlider = styled.input`
  width: 100%;
`;

const RangeTitle = styled.div`
  font-size: 18px;
`;

const formatter = Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
  maximumFractionDigits: 0,
});

const WhatsMyBudget = () => {
  const [cost, setCost] = useState(150000);
  const costRef = useRef(cost);
  const universe = useUniverse();
  const [hoursInFuture, setHoursInFuture] = useState(2);
  const hoursRef = useRef(hoursInFuture);
  const [finance, setFinance] = useState(() => calculateRolling(0, [], []));

  const date = dayjs().startOf('hour').add(hoursInFuture, 'hours');

  const txs = useTransactions({
    start: dayjs().subtract(1, 'month').startOf('day').subtract(1, 'hour'),
    excludeExpensed: true,
    amount_gt: -3000000,
  });

  const onCostChange = useMemo(
    () =>
      throttle(() => {
        const extraTxs: Transaction[] = Array.from(
          Array(hoursRef.current).keys(),
        ).map((n) => ({
          accountId: '',
          amount: costRef.current / hoursRef.current,
          categoryId: '',
          createdAt: dayjs().add(n, 'hours').toISOString(),
          expenseId: '',
          id: 'id' + n,
          ignore: false,
          selfBorrow: false,
          status: 'BOOKED',
          tags: [],
          title: '',
          userId: '',
        }));

        const transactions = [...txs, ...extraTxs];

        const result = calculateRolling(
          42000 * 100,
          transactions,
          [],
          dayjs().add(hoursRef.current, 'hours'),
        );
        setFinance(result);
      }, 100),
    [],
  );

  useEffect(() => {
    costRef.current = cost;
    hoursRef.current = hoursInFuture;
    onCostChange();
  }, [cost, hoursInFuture]);

  return (
    <Wrapper>
      <Title>What's My Budget</Title>
      <Subtitle>Can you afford it?</Subtitle>

      <Quality
        style={{
          backgroundColor: finance.color,
          marginBottom: 40,
        }}
      >
        {finance.moneyQuality}
      </Quality>

      <RangeTitle>
        Total money spent: {formatter.format(Math.round(cost / 100))}
      </RangeTitle>
      <RangeSlider
        type="range"
        min="1"
        max={universe.budget / 2.5}
        step={100 * 100}
        value={cost}
        onChange={(e) => setCost(Number(e.target.value))}
      />

      <RangeTitle>By this time: {date.format('DD/MM HH:mm')}</RangeTitle>
      <RangeSlider
        type="range"
        min="0"
        max={14 * 24}
        value={hoursInFuture}
        onChange={(e) => setHoursInFuture(Number(e.target.value))}
      />
    </Wrapper>
  );
};

export default WhatsMyBudget;
