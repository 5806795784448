import styled from '@emotion/styled';
import { useEffect, useLayoutEffect } from 'react';
import usePrevious from 'src/lib/hooks/usePrevious';

import CountUp from 'react-countup';
import React from 'react';
import {
  easeInOutCubic,
  easeInOutQuad,
  easeOutCubic,
} from 'use-animate-number/lib/easingFunctions';

const Quality = styled.div`
  font-size: 56px;
  text-align: center;
  margin: 2px 80px;
  background: #6ee16e;
  box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2;
  transition: all 1s ease-in-out;
`;

const DiffToYesterday = styled.div`
  opacity: 0.8;
  position: absolute;
  top: 45%;
  right: 9px;
  font-size: 12px;
`;

interface Props {
  color: string;
  number: number;
  diff: number;
  onClick?: () => void;
}

// https://logo.clearbit.com/spotify.com

const QualityNumber: React.FC<Props> = ({ color, onClick, number, diff }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const previous = usePrevious(number);

  useLayoutEffect(() => {
    if (!previous) {
      return;
    }

    const duration = 4000;
    const start = Date.now();

    /**
     * Animating the number going down literally feels like death,
     * so we just set the number directly.
     */
    if (number < previous) {
      ref.current!.innerHTML = number.toString();
      return;
    }

    const id = setInterval(() => {
      const elapsed = Math.min(duration, Date.now() - start);
      const num = Math.round(
        easeInOutCubic(elapsed, previous, number - previous, duration),
      );

      ref.current!.innerHTML = num.toString();

      if (num === number) {
        clearInterval(id);
      }
    }, 16);

    return () => clearInterval(id);
  }, [number]);

  return (
    <Quality
      id="quality"
      onClick={onClick}
      style={{
        background: color,
      }}
    >
      <div ref={ref}>{previous || number}</div>
      {/*<DiffToYesterday>{diff > 0 ? '+' + diff : diff}</DiffToYesterday>*/}
    </Quality>
  );
};

export default React.memo(QualityNumber, (prev, next) => {
  if (prev.number !== next.number) {
    return false;
  }

  if (prev.diff !== next.diff) {
    return false;
  }

  return true;
});
