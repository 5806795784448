import React, { useState } from 'react';
import { useAPI } from 'src/Components/API';
import Button from 'src/Components/Button';
import { useUniverse } from 'src/Components/Universe';

const Savings = () => {
  const universe = useUniverse();
  const api = useAPI();
  const [budget, setBudget] = useState(universe.budget / 100);

  function submitBudget() {
    api.updateUserSettings({ budget: budget * 100 });
  }

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <h1
        onClick={() => {
          localStorage.removeItem('token');
          localStorage.removeItem('txs');
          localStorage.removeItem('boot');
          localStorage.removeItem('expenses');
          window.location.reload();
        }}
      >
        Log out
      </h1>

      <h1
        onClick={() => {
          window.location.href = `https://link.tink.com/1.0/authorize/?client_id=e138d293a7114ac99c23f9fdc1857b5d&redirect_uri=https%3A%2F%2Fy4exypfwd4nafmopc5gqgzcejy0rqtqy.lambda-url.eu-north-1.on.aws%2F&market=SE&locale=en_US&scope=accounts:read,investments:read,transactions:read,user:read,identity:read&input_username=199304020770`;
        }}
      >
        Refresh
      </h1>
      <br />
      <br />
      <h1>Budget:</h1>
      <input
        type="number"
        value={budget}
        onChange={(e) => setBudget(parseInt(e.target.value))}
      />
      <Button onClick={submitBudget}>Submit</Button>
    </div>
  );
};

export default Savings;
