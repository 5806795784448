import React, { useEffect, useState } from 'react';
import { percentageToHsl } from '../../Components/FinanceCalculator';
import { Quality, Quote } from '../Home';
import useAnimateNumber from 'use-animate-number';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: 100vh;
  background-color: #aec6ce;
  padding: 20px;
`;

const Content = styled.div`
  padding: 20px;
  background-color: #f5f5dd;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;

const FadeBlock = styled.div<{
  visible: boolean;
}>`
  position: absolute;
  width: 100%;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: translateY(${(props) => (props.visible ? 0 : 5)}px);
  transition: all 0.2s ease-in-out;
`;

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 16px;
  padding: 0 30px;
  opacity: 0.8;
  margin-top: 8px;
`;

const Nextbutton = styled.div<{
  highlighted: boolean;
}>`
  background: ${(props) => (props.highlighted ? '#576eac' : '#779ecc')};
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 50px;

  transition: all 0.12s ease-out;

  ${(props) =>
    props.highlighted &&
    `
    box-shadow: none;
  `}
`;
const TextArea = styled.div`
  height: 150px;
  position: relative;
`;

interface Props {
  onFinished: Function;
}

const Onboarding: React.FC<Props> = ({ onFinished }) => {
  const [touching, setTouching] = useState(false);
  const [step, setStep] = useState(0);
  const [quality, setQuality] = useAnimateNumber(500, {
    decimals: 0,
    duration: 2000,
    enterance: false,
    easing: 'easeInOutCubic',
  });

  const color = percentageToHsl(
    Math.min(1, Math.max(0, quality / 1000) ** 4),
    0,
    120,
  );

  useEffect(() => {
    setTimeout(() => {
      // setStep(1);
    }, 1500);

    setTimeout(() => {
      // setStep(2);
    }, 3500);
  }, []);

  useEffect(() => {
    switch (step) {
      case 1:
        setQuality(850, false);
        break;
      case 2:
        setQuality(1000, false);
        break;
      case 3:
        setQuality(450, false);
        break;
      case 4:
        setQuality(1256, false);
        break;
      case 6:
        setQuality(250, false);
        break;
      case 7:
        setQuality(1325, false);
        break;
      case 9:
        onFinished();
        break;
      default:
        break;
    }
  }, [step]);

  return (
    <Wrapper>
      <Content>
        <TextArea
          style={{
            height: 100,
          }}
        >
          <FadeBlock visible={step > 0 && step < 8 && step !== 5}>
            <Quality
              style={{
                color: '#000',
                backgroundColor: color,
              }}
            >
              {quality}
            </Quality>
          </FadeBlock>
        </TextArea>
        <TextArea>
          <FadeBlock visible={step === 0 || step === 1 || step === 2}>
            <Title>Welcome</Title>
            <FadeBlock visible={step === 0}>
              <Subtitle>
                This app is all about your score. Your score is a representation
                of your current financial health. No matter your budget or how
                much money you have in your account, your score will be
                somewhere around 0-2000.
              </Subtitle>
            </FadeBlock>

            <FadeBlock visible={step === 1}>
              <Subtitle>Here's what a score looks like. 850 is "ok".</Subtitle>
            </FadeBlock>

            <FadeBlock visible={step === 2}>
              <Subtitle>
                Try to keep it around 1000. Anything around 1000 means your
                finances are in good shape and you haven't overspent lately.
              </Subtitle>
            </FadeBlock>
          </FadeBlock>
          <FadeBlock visible={step === 3}>
            <Title>Tough times</Title>
            <Subtitle>
              If you overspend, your score will drop towards zero.
              <br /> <br />
              If this happens, don't worry! The app will tell you how to get
              back on track.
            </Subtitle>
          </FadeBlock>

          <FadeBlock visible={step === 4}>
            <Title>Grand times</Title>
            <Subtitle>
              If your score goes above 1000, it means you are spending less than
              you earn. This is where you want to be! If you have set up
              savings, dreams and other goals, money will automatically be
              allocated towards them 🎉
            </Subtitle>
          </FadeBlock>

          <FadeBlock visible={step === 5 || step === 6 || step === 7}>
            <Title>Your assistant</Title>
            <Subtitle>
              Your assistant will be by your side to give you feedback on your
              journey. The assistant has a lot of different personalities.
              Sometimes it writes poetry, sometimes it gives you concrete
              advice.
              <br /> <br /> Your assistant can be a bit insane sometimes, so
              dont read too much into what they say
            </Subtitle>
          </FadeBlock>

          <FadeBlock visible={step === 8}>
            <Title>This is it!</Title>
            <Subtitle>
              Time to dive in! Hope this intro helped. Press the button below to
              continue.
            </Subtitle>
          </FadeBlock>
        </TextArea>

        <FadeBlock
          visible={step === 6}
          style={{
            width: '80%',
          }}
        >
          <TextArea
            style={{
              height: 240,
            }}
          >
            <Quote>
              {`You're not doing too great,

Your finances are in a bit of a bind.

But don't despair, things could always be worse,

And with a little effort, you can turn them around!`}
            </Quote>
          </TextArea>
        </FadeBlock>
        <TextArea>
          <FadeBlock visible={step === 7}>
            <Quote>
              {`🎉🎊🎈🎁🎂 Congratulations!!! You're doing amazing!!! :) :) :)`}
            </Quote>
          </FadeBlock>
        </TextArea>
        <TextArea />
        <Nextbutton
          highlighted={touching}
          onTouchStart={() => {
            setTouching(true);
          }}
          onTouchEnd={() => {
            setTouching(false);
          }}
          onClick={() => {
            setStep((s) => s + 1);
            setTouching(false);
          }}
        >
          Next
        </Nextbutton>
      </Content>
    </Wrapper>
  );
};

export default Onboarding;
