import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import TripBG from './trip.png';

const Wrapper = styled.div`
  padding-top: 5px;

  & * {
    overflow: visible;
  }
`;

const Overlay = styled.div<{
  visible: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  z-index: 10000;
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? 'scale(1)' : 'scale(1.1)')};
  transition: all 0.25s ease-out;
`;

const TextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  padding-bottom: 40px;
  color: #fff;
  z-index: 2;
`;

const Title = styled.div`
  font-size: 26px;
  position: relative;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 18px;
  opacity: 0.8;
  margin-top: 15px;
  white-space: pre-line;
  line-height: 1.35;
  position: relative;
`;

const Preview = styled.div`
  margin: 20px;
  padding: 20px;
  background: #f6fff6;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
`;

const StoryCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #6ee16e;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  & > * {
    animation: fade-in-delayed 0.35s ease-out;
    animation-fill-mode: forwards;
  }
`;

const Background = styled.img`
  position: absolute;
  top: 2%;
  left: 5%;
  width: 90%;
  z-index: -1;
  border-radius: 5px;
`;

const BlurredBackground = styled.img`
  position: absolute;
  top: 8%;
  left: 3%;
  width: 94%;
  z-index: -1;
  filter: blur(40px);
`;

// Fade from black to transparent
const FadedBackground = styled.div`
  position: absolute;
  bottom: 11.4%;
  border-radius: 5px;
  left: 5%;
  width: 90%;
  height: 80vh;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`;

interface Props {
  showStory: boolean;
  setShowStory: (show: boolean) => void;
}

const Stories: React.FC<Props> = ({ showStory, setShowStory }) => {
  // Reset some components on hide so they can be animated again
  const [key, setKey] = React.useState(0);
  const [shouldShow, setShouldShow] = React.useState(false);

  useEffect(() => {
    setKey((key) => key + 1);

    setTimeout(() => {
      setShouldShow(showStory);
    }, 5);
  }, [showStory]);

  return (
    <Wrapper>
      <Overlay visible={shouldShow} onClick={() => setShowStory(false)}>
        <BackgroundWrapper>
          <BlurredBackground src="https://replicate.delivery/pbxt/jUuEcyBLswpHAVGD97ePidQvE8eINhqIrJalfyHfWqoqYGBAB/out-0.png" />
        </BackgroundWrapper>
        <TextWrapper>
          <FadedBackground />
          <Title>Trip with friends hit 50%!</Title>
          <Description>{`You hit 50% progress on your dream
Now it's time to make a plan
You'll need some money saved up
So you can go and take that trip
With friends, it will be so fun
You'll make memories that will last
And when you come back, you'll be glad
You took the time to make your dream come true`}</Description>
        </TextWrapper>

        <BackgroundWrapper key={key}>
          <Background
            key={key}
            src="https://replicate.delivery/pbxt/jUuEcyBLswpHAVGD97ePidQvE8eINhqIrJalfyHfWqoqYGBAB/out-0.png"
          />
        </BackgroundWrapper>
      </Overlay>
    </Wrapper>
  );
};

export default Stories;
