import React from 'react';
import styled from '@emotion/styled';
import Bar from './Bar';
import { ResponsiveBar, ResponsiveBarCanvas } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { BarChart, ResponsiveContainer } from 'recharts';

const Wrapper = styled.div`
  padding: 5px;
  position: relative;
  margin-bottom: 20px;
`;

const BarWrapper = styled.div`
  display: flex;

  height: 100px;
  width: 100%;

  background: rgba(50, 50, 50, 0.2);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  border-radius: 3px;

  align-items: center;
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 11px;
  font-weight: bold;
`;

const Title = styled.div`
  font-size: 16px;
  text-align: center;
  margin: 0 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #88b2e0;
  padding-bottom: 10px;
`;

interface Props {
  title: string;
  values: Array<{
    label: string;
    value: number;
  }>;
  pie?: boolean;
}

const Graph: React.FC<Props> = ({ values, pie, title }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <BarWrapper>
        <Text>{'<insert graph here>'}</Text>
      </BarWrapper>
    </Wrapper>
  );
};

export default Graph;
