import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useAPI } from '../API';
import { useFinance } from '../FinanceProvider';

interface BootData {
  date: string;
  personalData: {
    hasActiveToken: boolean;
    name: string;
    savingsActivated: boolean;
    budget: number | null;
  } | null;
}

export interface Transaction {
  id: string;
  accountId: string;
  amount: number;
  categoryId: string;
  createdAt: string;
  expenseId: string;
  title: string;
  userId: string;
  ignore: boolean;
  tags: Tag[];
  status: 'PENDING' | 'BOOKED';
}

interface RawTransaction extends Omit<Transaction, 'tags'> {
  tags: string[];
}

export interface Tag {
  id: string;
  createdAt: string;
  title: string;
  color: string;
  lastUsed?: string;
}

export interface Expense {
  id: string;
  amount: number;
  createdAt: string;
  title: string;
  userId: string;
  period: 'MONTHLY' | 'WEEKLY';
}

export interface Dream {
  id: string;
  createdAt: string;
  title: string;
  image: string | null;
  fulfilled: boolean;
  target: number;
  estimatedDate: string;
  deadline: string | null;
  totalSaved: number;
  balance: number;
}

interface Universe {
  loading: boolean;
  isLoggedIn: boolean;
  savingsActivated: boolean;
  user: {
    name: string;
  } | null;
  budget: number;
  hasActiveToken: boolean;
  transactions: Transaction[];
  tags: Tag[];
  dreams: Dream[];
  expenses: Expense[];

  refetch: () => void;

  api: {
    updateTransaction(
      id: string,
      data: {
        tags?: string[];
        expenseId?: string | null;
        ignore?: boolean;
      },
    ): void;
  };
}

// @ts-ignore
const APIContext = React.createContext<Universe>({});

interface Props {
  children: React.ReactNode;
}

export const UniverseProvider: React.FunctionComponent<Props> = ({
  children,
}) => {
  const financeApi = useFinance();
  const api = useAPI();
  const [bootData, setBootData] = useState<BootData | null>(
    JSON.parse(localStorage.getItem('boot') || '{}'),
  );
  const [tags, setTags] = useState<Tag[]>([]);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [dreams, setDreams] = useState<Dream[]>([]);
  const [transactions, setTransactions] = useState<RawTransaction[]>(
    JSON.parse(localStorage.getItem('txs') || '[]') || [],
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const expensesInStore = JSON.parse(
      localStorage.getItem('expenses') || '[]',
    );

    if (expensesInStore.length) {
      setExpenses(expensesInStore);
    }
  }, []);

  async function fetchData(noClientBoot = false) {
    financeApi.refetch();
    const results = await Promise.all([
      noClientBoot
        ? Promise.resolve()
        : api.clientBoot().then((d) => {
            setBootData(d);

            if (d.personalData) {
              setDreams(d.personalData.dreams);
            }

            if (d) {
              localStorage.setItem('boot', JSON.stringify(d));
            }
          }),

      api.tags().then((data) => setTags(data)),

      api.expenses().then((data) => {
        if (data?.success === false) {
          return;
        }

        if (data) {
          setExpenses(data);
          localStorage.setItem('expenses', JSON.stringify(data));
        }
      }),

      api.transactions().then((data) => {
        const txs = data.transactions ?? [];
        setTransactions(txs);

        if (txs) {
          localStorage.setItem('txs', JSON.stringify(txs));
        }
      }),
    ]);

    setLoading(false);

    return results;
  }

  useEffect(() => {
    api.clientBoot().then((d) => {
      setBootData(d);

      if (d.personalData) {
        setDreams(d.personalData.dreams);
        fetchData(true);
      }
      localStorage.setItem('boot', JSON.stringify(d));
    });
  }, []);

  const txs = transactions.map((tx) => ({
    ...tx,
    tags: (tx.tags ?? [])
      .map((id) => tags.find((tag) => tag.id === id)!)
      .filter(Boolean),
  }));

  const universe: Universe = {
    loading,
    budget: bootData?.personalData?.budget || 0,
    isLoggedIn: Boolean(bootData?.personalData),
    savingsActivated: Boolean(bootData?.personalData?.savingsActivated),
    hasActiveToken: Boolean(bootData?.personalData?.hasActiveToken),
    tags: tags,
    dreams,
    transactions: txs,
    expenses,

    refetch: fetchData,

    user: bootData?.personalData
      ? {
          name: bootData.personalData.name,
        }
      : null,

    api: {
      // Mark a transaction as ignored and refetch
      updateTransaction: async (id, data) => {
        await api.updateTransaction(id, data);

        return fetchData(true);
      },
    },
  };

  return <APIContext.Provider value={universe}>{children}</APIContext.Provider>;
};

export const useUniverse = () => useContext(APIContext);
