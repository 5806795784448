import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { useAPI } from 'src/Components/API';
import Button from 'src/Components/Button';
import { percentageToHsl } from 'src/Components/FinanceCalculator';
import { useNavigation } from 'src/Components/Navigation';
import { useUniverse } from 'src/Components/Universe';

const Wrapper = styled.div`
  margin: 20px;
  padding: 20px 0;
  margin-bottom: 120px;
`;

const Dream = styled.div`
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
  margin-top: 15px;
  z-index: 2;
`;

const OuterDream = styled.div<{ selected: boolean }>`
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: scale(${(p) => (p.selected ? 1.05 : 1)});
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  bottom: 10px;
`;

const Content = styled.div<{ selected: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 260px;
  transform: translateY(${(p) => (p.selected ? 0 : 185)}px);
  padding: 20px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  transition: all 0.2s ease-in-out;
`;

const EstimatedDate = styled.div`
  font-size: 12px;
`;

const Image = styled.img`
  width: 100%;
  height: 320px;
  object-fit: cover;
`;

const BlurredBackground = styled.img<{ selected: boolean }>`
  position: absolute;
  top: ${(p) => (p.selected ? '-3%' : '-1%')};
  left: 3%;
  width: 94%;
  filter: blur(${(p) => (p.selected ? '20px' : '10px')});
  opacity: ${(p) => (p.selected ? '0.4' : '0.6')};

  transition: all 0.2s ease-in-out;

  height: 220px;
  object-fit: cover;
`;

const ProgressBar = styled.div<{ background?: boolean; width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width}%;
  height: 5px;
  background: ${(props) => (props.background ? '#cccccc77' : '#6ee16e')};
`;

const RemoveIcon = styled.div`
  position: absolute;
  top: 5px;
  right: -5px;
  padding: 20px;
`;

const Progress = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #333;
  font-size: 16px;
  background: #6ee16e;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #6ee16e;
  padding: 10px;
  font-weight: bold;
`;

const DreamsInfo = styled.div`
  padding: 20px;
  border-radius: 7px;
  margin-top: 15px;
  z-index: 2;
  text-align: center;

  > p {
    color: #555;
  }
`;

const Dreams = () => {
  const naviage = useNavigation();
  const api = useAPI();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const universe = useUniverse();

  const progress = (dream: any) =>
    Math.round((dream.balance / dream.target) * 100) || 0;

  const color = (n: number) =>
    percentageToHsl(Math.min(1, Math.max(0, n)), 60, 120);

  const sorted = [...universe.dreams]
    .reverse()
    .filter((dream) => !dream.fulfilled);

  if (!sorted.length) {
    return (
      <Wrapper>
        <DreamsInfo>
          <h1>Dreams</h1>
          <p>
            <br />
            Thinking of a trip somewhere? <br /> A new pair of pants?
            <br />
            <br />
            Let your dreams become a reality - create a dream and start saving
            for it. You can choose to save{' '}
            <b>✨ whenever you have some extra cash✨</b> or a little bit each
            week. Your choice!
            <br />
            <br />
            <Button onClick={() => naviage.setRoute('create-dream')}>
              Create a dream ✨
            </Button>
          </p>
        </DreamsInfo>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1>Dreams</h1>

      <br />
      <Button
        onClick={() => {
          naviage.setRoute('create-dream');
        }}
      >
        Create a dream
      </Button>
      <br />

      {sorted.map((dream) => (
        <OuterDream
          key={dream.id}
          selected={selectedId === dream.id}
          onClick={() =>
            setSelectedId(selectedId === dream.id ? null : dream.id)
          }
        >
          <BlurredBackground
            selected={selectedId === dream.id}
            src={dream.image!}
          />
          <Dream>
            <Image src={dream.image!} />

            <Content selected={selectedId === dream.id}>
              <Title>{dream.title}</Title>
              <ProgressBar background width={100} />
              <ProgressBar width={progress(dream)} />
              <div
                style={{
                  transition: 'all 0.2s ease-in-out',
                  opacity: selectedId === dream.id ? 0 : 1,
                  pointerEvents: selectedId === dream.id ? 'all' : 'none',
                }}
              >
                <Progress
                  style={{
                    backgroundColor: color(progress(dream) / 100),
                  }}
                >
                  {progress(dream)}%
                </Progress>
              </div>
              <EstimatedDate>
                {dayjs(dream.estimatedDate).format('DD/MM HH:mm')}
              </EstimatedDate>

              <div
                style={{
                  transition: 'all 0.2s ease-in-out',
                  opacity: selectedId === dream.id ? 1 : 0,
                  pointerEvents: selectedId === dream.id ? 'all' : 'none',
                }}
              >
                <RemoveIcon
                  onClick={async (e) => {
                    e.stopPropagation();
                    await api.deleteDream(dream.id);
                    universe.refetch();
                  }}
                >
                  <FaTrashAlt color={'#c0392b'} />
                </RemoveIcon>
                <br />
                <Button
                  disabled={dream.balance < dream.target}
                  onClick={async () => {
                    // Remove dream and withdraw money
                    await api.fulfillDream(dream.id);
                    universe.refetch();
                  }}
                  green
                >
                  ✨ Use ✨
                </Button>
                <br />

                <Button
                  onClick={async () => {
                    await api.withdrawDream(dream.id);
                    await universe.refetch();
                  }}
                >
                  Withdraw
                </Button>
              </div>
            </Content>
          </Dream>
        </OuterDream>
      ))}
    </Wrapper>
  );
};

export default Dreams;
