import React, { useEffect } from 'react';
import { useUniverse } from '../Universe';

const MessageHandler: React.FC = () => {
  const universe = useUniverse();

  useEffect(() => {
    async function handle(event: Event) {
      const parsed: {
        type: string;
        payload: any;

        // @ts-ignore
      } = event.data;
      if (parsed.type === 'appState') {
        universe.refetch();
      }
    }

    document.addEventListener('message', handle);

    return () => document.removeEventListener('message', handle);
  }, []);

  return null;
};

export default MessageHandler;
