import React, { useState } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div<{
  highlighted: boolean;
  green?: boolean;
  disabled?: boolean;
}>`
  background: ${(props) => (props.highlighted ? '#576eac' : '#779ecc')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  font-weight: bold;

  transition: all 0.12s ease-out;

  ${(props) =>
    props.highlighted &&
    `
    box-shadow: none;
  `}

  ${(props) =>
    props.green &&
    `
    background:
    ${props.highlighted ? '#2c8f20' : '#4caf50'};
  `}
`;

interface Props {
  green?: boolean;
  onClick: () => void;
  children?: any;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

const Button: React.FunctionComponent<Props> = ({
  onClick,
  children,
  disabled,
  green,
  type,
}) => {
  const [touching, setTouching] = useState(false);

  // A matte red hex color for the button
  const red = '#c0392b';

  return (
    <Wrapper
      highlighted={touching}
      disabled={disabled}
      green={green}
      onTouchStart={(e) => {
        e.stopPropagation();
        if (disabled) {
          return;
        }

        setTouching(true);
      }}
      onTouchEnd={() => {
        setTouching(false);
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
        setTouching(false);
      }}
    >
      {children}
    </Wrapper>
  );
};

export default Button;
