import dayjs from 'dayjs';
import { Transaction } from '../../Components/Universe';

const findBudget = (
  transactions: Transaction[],
  mode: 'largest-income' | 'average' = 'largest-income',
) => {
  const largestIncome = Math.abs(
    Math.min(...transactions.map((t) => t.amount)),
  );

  // Getting budget by taking average spend of past two months
  if (mode === 'average') {
    const sorted = [...transactions.filter((tx) => tx.amount > 0)].sort(
      (a, b) => (dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1),
    );
    const start = dayjs(sorted[0]?.createdAt);
    const end = dayjs(sorted[sorted.length - 1]?.createdAt);
    const diffDays = Math.abs(start.diff(end, 'days'));
    const sum = sorted
      .filter((tx) => tx.amount > -largestIncome * 0.8)
      .reduce((sum, tx) => sum + tx.amount, 0);
    const averagePerDay = sum / diffDays;

    return averagePerDay * 30 ?? 0;
  }

  // Just returning the largest income transaction (likely their salary)
  return largestIncome ?? 0;
};

export default findBudget;
