import dayjs from 'dayjs';
import { useFinance } from '../FinanceProvider';
import { Dream, useUniverse } from '../Universe';

interface Allocation {
  dreamId: string;
  amount: number;
  dreamTarget?: number;
}

/**
 * The stasher.
 *
 * The stasher calculates how much money should be moved into
 * the savings account.
 *
 * There's two types of allocations: Fixed and flexible.
 * Fixed allocations are things like dreams with a deadline and
 * hard savings goals (like saving 20% of your income each month).
 * Flexible allocations are things like "I want to buy a pair of
 * pants when I can afford it".
 */
const useStasher = () => {
  const finance = useFinance();
  const universe = useUniverse();

  const budget = universe.budget;
  const remaining = finance.rolling.remaining;

  const dreams = universe.dreams;

  const deadlineDreams = dreams.filter((d) => !!d.deadline);

  const moneyToAllocate = Math.round(remaining * 0.75);

  const dreamsWithoutDeadline = dreams.filter((d) => !d.deadline);

  let remainingMoney = moneyToAllocate;

  const allocations: Allocation[] = [];

  /**
   * Spread the money across dreams.
   */
  for (const dream of deadlineDreams) {
    const remaining = dream.target - dream.balance;
    const date = dayjs(dream.deadline!);
    const start = dayjs(dream.createdAt);
    const daysLeft = date.diff(dayjs(), 'day');

    // Calculate how much we should have saved by now
    const shouldHaveSaved = (remaining / daysLeft) * start.diff(dayjs(), 'day');
    const diff = shouldHaveSaved - dream.balance;

    // If we have a considerable diff, allocate
    if (diff > 500) {
      allocations.push({
        dreamId: dream.id,
        amount: diff,
      });

      remainingMoney -= diff;
    }
  }

  /**
   * Spread the money evenly across all flexible dreams.
   *
   * First we calculate how much money each dream should get,
   * then we allocate it.
   */
  const moneyPerDream = Math.round(
    remainingMoney / dreamsWithoutDeadline.length,
  );
  for (const dream of dreamsWithoutDeadline) {
    const allocationOrMax = Math.min(
      dream.target - dream.balance,
      moneyPerDream,
    );

    allocations.push({
      dreamId: dream.id,
      amount: Math.min(allocationOrMax, remainingMoney),
    });
  }

  const total = allocations.reduce((acc, cur) => acc + cur.amount, 0);

  return {
    total: total / 100,
    remaining: remaining / 100,
    moneyToAllocate: Math.round(moneyToAllocate / 100) * 100,
    allocations,
  };
};

export default useStasher;
