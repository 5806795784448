import React from 'react';
import { useContext } from 'react';

const session = localStorage.getItem('token');

function request(
  method: 'GET' | 'POST' | 'DELETE',
  route: string,
  body: object = {},
) {
  const headers = new Headers();
  headers.append('x-session-key', session!);

  // If its a GET request, convert the body to a query string
  if (method === 'GET') {
    const query = Object.keys(body)
      // @ts-ignore
      .map((key) => `${key}=${body[key]}`)
      .join('&');
    route += `?${query}`;
  }

  return fetch(
    `https://jcqnfnov97.execute-api.eu-north-1.amazonaws.com/${route}`,
    {
      credentials: 'include',
      method,
      body: method === 'POST' ? JSON.stringify(body) : undefined,
      headers,
    },
  ).then((d) => d.json());
}

const api = {
  clientBoot() {
    return request('GET', 'client-boot');
  },

  createTag(title: string, color: string, transactionId: string) {
    return request('POST', 'tags', {
      title,
      color,
      transactionId,
    });
  },

  tags() {
    return request('GET', 'tags');
  },

  expenses() {
    return request('GET', 'expenses');
  },

  createExpense(data: {
    amount: number;
    title: string;
    period: 'MONTHLY' | 'WEEKLY';
  }) {
    return request('POST', 'expenses', data);
  },

  createDream(data: {
    title: string;
    amount: number;
    deadline: string | null;
  }) {
    return request('POST', 'dreams', data);
  },

  fulfillDream(id: string) {
    return request('POST', `dreams/${id}/fulfill`);
  },

  deleteDream(id: string) {
    return request('POST', `dreams/${id}/delete`);
  },

  tag(id: string) {
    return request('GET', 'tags/' + id);
  },

  register(name: string, personalID: string) {
    return request('POST', 'register', { name, personalID });
  },

  login(data: { id: string; name: string }) {
    return request('POST', 'login', data);
  },

  transactions() {
    return request('GET', 'transactions');
  },

  quote(
    quality: number,
    yesterdayScore: number,
    target: number,
    topCategories: Array<{
      name: string;
      amount: number;
    }>,
    isInsights?: boolean,
  ) {
    return request('POST', 'quote', {
      quality,
      yesterdayScore,
      target,
      topCategories,
      isInsights,
    });
  },

  markQuoteSeen(id: string) {
    return request('POST', 'quote', { quoteId: id });
  },

  updateTransaction(
    id: string,
    data: {
      tags?: string[];
      expenseId?: string | null;
      ignore?: boolean;
      markAsSavings?: boolean;
    },
  ) {
    return request('POST', 'transactions/' + id, data);
  },

  banks(country: string) {
    return request('GET', 'banks', { country });
  },

  accounts() {
    return request('GET', 'accounts');
  },

  withdrawDream(id: string) {
    return request('POST', 'dreams/' + id + '/withdraw');
  },

  deleteAccount(id: string) {
    return request('DELETE', 'accounts/' + id);
  },

  createBankSession(bankId: string, redirectUrl: string) {
    return request('POST', 'bank-session', { bankId, redirectUrl });
  },

  updateUserSettings(data: { budget?: number; activateSavings?: boolean }) {
    return request('POST', 'settings', data);
  },
};

const APIContext = React.createContext(api);

interface Props {
  children: React.ReactNode;
}

export const APIProvider: React.FunctionComponent<Props> = ({ children }) => {
  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};

export const useAPI = () => useContext(APIContext);
