import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import categories from '../../mock-data/categories.json';
import { useAPI } from '../../Components/API';
import { FaPiggyBank, FaToggleOff } from 'react-icons/fa';
import { TiCancel, TiThumbsOk } from 'react-icons/ti';
import { useUniverse, Transaction } from '../../Components/Universe';

const HEIGHT = 20;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const BackgroundTop = styled.div`
  position: absolute;
  top: ${HEIGHT + 4}px;
  left: 0;
  width: 100%;
  height: 280px;
  background: linear-gradient(to bottom, #f5f5ddff 0%, #f5f5dd00 100%);
`;

const BackgroundBottom = styled.div`
  position: absolute;
  bottom: ${HEIGHT}px;
  left: 0;
  width: 100%;
  height: 280px;
  background: linear-gradient(to top, #f5f5ddff 0%, #f5f5dd00 100%);
`;

const TagsWrapper = styled.div`
  margin: 10px 0;
  padding: 20px;
  background: #fff;
  position: absolute;
  z-index: 20;
  border: 1px solid #ccc;
`;

const CloseButton = styled.div`
  border: 2px solid rgb(117, 36, 36);
  color: rgb(117, 36, 36);
  padding: 8px;
  font-size: 18px;
  width: 200px;
  text-align: center;
  margin: 0 auto;
  margin-top: 25px;
  border-radius: 4px;
`;

const IgnoreButton = styled.div<{
  offset: number;
}>`
  margin: 4px;
  background: rgb(200, 0, 0);
  color: #fff;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  top: 25px;
  left: ${(props) => props.offset}px;
  border-radius: 8px;
  background: rgb(117, 36, 36);
  color: #fff;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: bold;
  padding-left: 27px;

  animation-name: bounce-in-bottom-right;
  animation-duration: 0.35s;
  animation-delay: 0.05s;
  animation-timing-function: cubic-bezier(0.31, 0.71, 0.42, 1.26);
`;

const TagInput = styled.input`
  margin: 10px 0;
  background: #fafafa;
  padding: 9px;
  border: 0;
  font-size: 18px;
  border: 1px solid #ccc;
  width: calc(100%);
  border-radius: 2px;
`;

const TagButton = styled.div<{
  offset: number;
}>`
  margin: 4px;
  background: rgb(117, 36, 36);
  color: #fff;
  padding: 6px 10px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -52px;
  left: ${(props) => props.offset}px;
  border-radius: 8px;
  animation-name: bounce-in-top-right;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.31, 0.71, 0.42, 1.26);
  perspective: 200px;
  transform-style: preserve-3d;

  &:hover,
  &:active,
  &:focus {
    transform: translate3d(0, -2px, 0);
  }
`;

const Title = styled.div`
  font-size: 18px;
`;

const Tag = styled.div`
  padding: 7px;
  margin: 3px;
  border-radius: 3px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TagColor = styled.div<{
  color: string;
  selected: boolean;
}>`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 10px;
  display: inline-block;
  background: ${(props) => props.color};
  border: 2px solid ${(props) => (props.selected ? '#333' : '#ccc')};
`;

const Expense = styled.div`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(2px);
  padding: 8px;

  animation-name: bounce-up;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  width: 200px;
  opacity: 0;

  position: relative;
  z-index: 2;
  border-radius: 3px;
  margin: 3px;
  top: -20px;
`;

interface Props {
  tx: Transaction;
  onClose: () => void;
  offset: number;
}

const COLORS = ['#ffb9b9', '#ffffcc', '#ccffcc', '#ccecff', '#ccccff'];

const TransactionOverlay: React.FC<Props> = ({ offset, tx, onClose }) => {
  const [text, setText] = useState('');
  const api = useAPI();
  const [selectedColor, setSelectedColor] = useState(0);
  const universe = useUniverse();

  const [pickExpenseMode, setPickExpenseMode] = useState(false);
  const [tagMode, setTagMode] = useState(false);

  function handleSaveTag(e: React.FormEvent) {
    e.preventDefault();

    if (!text) {
      return;
    }

    setText('');

    api.createTag(text, COLORS[selectedColor], tx.id);
  }

  return (
    <Wrapper>
      <BackgroundTop
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
      <BackgroundBottom
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
      {pickExpenseMode && (
        <Content>
          {universe.expenses.map((exp, i) => (
            <Expense
              style={{
                animationDelay: `${(universe.expenses.length - 1 - i) * 100}ms`,
              }}
              onClick={() => {
                universe.api.updateTransaction(tx.id, {
                  expenseId: exp.id,
                });
              }}
            >
              {exp.title}
            </Expense>
          ))}

          <IgnoreButton
            offset={offset}
            style={{
              marginLeft: -70,
              marginTop: 50,
            }}
            onClick={() => setPickExpenseMode(false)}
          >
            <FaToggleOff
              size={19}
              style={{
                position: 'absolute',
                top: 4,
                left: 5,
              }}
            />
            Cancel
          </IgnoreButton>
        </Content>
      )}

      {tagMode && (
        <TagsWrapper>
          <Title>Create tag:</Title>
          <InputWrapper>
            <form onSubmit={handleSaveTag}>
              <TagInput
                value={text}
                placeholder="Title"
                onChange={(e) => setText(e.target.value)}
              />
            </form>
            <div>
              <TagColor
                onClick={() => setSelectedColor(0)}
                selected={selectedColor === 0}
                color="#ffb9b9"
              />
              <TagColor
                onClick={() => setSelectedColor(1)}
                selected={selectedColor === 1}
                color="#ffffcc"
              />
              <TagColor
                onClick={() => setSelectedColor(2)}
                selected={selectedColor === 2}
                color="#ccffcc"
              />
              <TagColor
                onClick={() => setSelectedColor(3)}
                selected={selectedColor === 3}
                color="#ccecff"
              />
              <TagColor
                onClick={() => setSelectedColor(4)}
                selected={selectedColor === 4}
                color="#ccccff"
              />
            </div>
            <div onClick={handleSaveTag}>Create</div>
          </InputWrapper>
          <Title>Added:</Title>
          <Tags>
            {tx.tags.map((tag) => (
              <Tag
                style={{
                  background: tag.color,
                }}
              >
                {tag.title}
              </Tag>
            ))}
          </Tags>
          <br />
          <Title>Available:</Title>
          <Tags>
            {universe.tags
              .filter((t) => !tx.tags.some((tag) => tag.id === t.id))
              .map((tag) => (
                <Tag
                  style={{
                    background: tag.color,
                  }}
                  onClick={() => {
                    universe.api.updateTransaction(tx.id, {
                      tags: [...tx.tags.map((t) => t.id), tag.id],
                    });
                  }}
                >
                  {tag.title}
                </Tag>
              ))}
          </Tags>
          <CloseButton onClick={() => setTagMode(false)}>Close</CloseButton>
        </TagsWrapper>
      )}
      {!tagMode && !pickExpenseMode && (
        <Content>
          {/*<TagButton
            style={{
              marginLeft: -20,
            }}
            offset={offset}
            onClick={() => setTagMode(true)}
          >
            Add tag
          </TagButton>
          <IgnoreButton
            offset={offset}
            style={{
              marginLeft: -100,
              animationName: 'bounce-in-bottom-left',
            }}
            onClick={() => setPickExpenseMode(true)}
          >
            <FaToggleOff
              size={19}
              style={{
                position: 'absolute',
                top: 4,
                left: 5,
              }}
            />
            Expense
          </IgnoreButton>*/}

          <TagButton
            offset={offset}
            style={{
              paddingLeft: 25,
            }}
            onClick={(e) => {
              api
                .updateTransaction(tx.id, {
                  markAsSavings: tx.categoryId !== 'SAVINGS',
                })
                .then(() => universe.refetch());

              e.stopPropagation();
              onClose();
            }}
          >
            <FaPiggyBank
              size={17}
              style={{
                position: 'absolute',
                top: 4,
                left: 5,
              }}
            />
            Mark as Savings
          </TagButton>

          <IgnoreButton
            offset={offset}
            onClick={(e) => {
              universe.api.updateTransaction(tx.id, {
                ignore: !tx.ignore,
              });

              e.stopPropagation();
              onClose();
            }}
          >
            {!tx.ignore && (
              <TiCancel
                size={22}
                style={{
                  position: 'absolute',
                  top: 3,
                  left: 3,
                }}
              />
            )}

            {tx.ignore && (
              <TiThumbsOk
                size={22}
                style={{
                  position: 'absolute',
                  top: 3,
                  left: 3,
                }}
              />
            )}
            {tx.ignore ? 'Un-ignore' : 'Ignore'}
          </IgnoreButton>
        </Content>
      )}
    </Wrapper>
  );
};

export default TransactionOverlay;
