import dayjs from 'dayjs';
import React, { useState } from 'react';
import Button from 'src/Components/Button';
import { useNavigation } from 'src/Components/Navigation';
import styled from '@emotion/styled';
import { useAPI } from '../../Components/API';

const Wrapper = styled.div`
  margin: 20px;
  padding: 20px 0;
`;

const HelpText = styled.div`
  font-size: 12px;
  opacity: 0.8;
`;

const FormField = styled.div`
  margin: 10px 0;
`;

const Title = styled.div`
  font-size: 16px;
`;

const Toggle = styled.div`
  background: #000;
  width: 50px;
  height: 50px;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  box-shadow: 0;
  outline: 0;
  padding: 8px;
  width: 100%;
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled.div`
  padding: 10px;
  background: #0f0;
  margin-top: 15px;
  border-radius: 2px;
`;

interface Props {
  initialData?: {
    id: string;
    title: string;
    date: string;
    period: string;
  };
}

const EditExpense: React.FC<Props> = ({ initialData }) => {
  const { setRoute } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(initialData?.title ?? '');
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState<null | Date>();
  const [recurring, setRecurring] = useState(false);
  const [period, setPeriod] = useState<'MONTHLY' | 'WEEKLY'>('MONTHLY');

  const api = useAPI();

  async function handleSubmit() {
    setLoading(true);
    if (initialData?.id) {
    } else {
      const result = await api.createExpense({
        amount,
        period,
        title,
      });

      if (result.success) {
        setRoute('home');
      }
    }

    setLoading(false);
  }

  return (
    <Wrapper>
      <FormField>
        <Title>Title</Title>
        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      </FormField>
      <FormField>
        <Title>Amount</Title>
        <Input
          type="number"
          value={amount}
          inputMode="decimal"
          onChange={(e) => setAmount(Number(e.target.value))}
        />
      </FormField>
      <FormField>
        <Row>
          <div>
            <Title>Recurring</Title>
            <HelpText>
              Enable this if this is an expense that happens at a regular
              interval
            </HelpText>
          </div>
          <Toggle onClick={() => setRecurring(!recurring)} />
        </Row>
      </FormField>

      {recurring && (
        <FormField>
          <Title>Period</Title>
          <HelpText>How often does this expense happen?</HelpText>
          <div onClick={() => setPeriod('WEEKLY')}>
            {period === 'WEEKLY' && '> '} WEEKLY
          </div>
          <div onClick={() => setPeriod('MONTHLY')}>
            {period === 'MONTHLY' && '> '} MONTHLY
          </div>
        </FormField>
      )}

      <Button disabled={loading} onClick={handleSubmit}>
        Create
      </Button>
    </Wrapper>
  );
};

export default EditExpense;
