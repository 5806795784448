const useBridge = () => {
  return {
    boot: () => {
      // Send a message to the react native webview wrapper
      // @ts-ignore
      window.ReactNativeWebView?.postMessage('boot', '*');
    },

    reload: () => {
      if (window.hasNativeWrapper) {
        window.ReactNativeWebView?.postMessage('reload');
      } else {
        window.location.reload();
      }
    },

    open: (url: string) => {
      // @ts-ignore
      if (window.ReactNativeWebView) {
        // Send a message to the react native webview wrapper
        // @ts-ignore
        window.ReactNativeWebView.postMessage('open:' + url);
        return;
      }
      window.location.href = url;
    },
  };
};

export default useBridge;
