import dayjs, { Dayjs } from 'dayjs';

const findSalaryDate = (date: Dayjs) => {
  let monthDate = (
    date.date() >= 25 ? date : date.subtract(1, 'month')
  ).startOf('month');

  monthDate = monthDate.add(25, 'days').subtract(5, 'hours');

  return monthDate;
};

export default findSalaryDate;
