import categoryData from '../../mock-data/categories.json';
import { Transaction } from 'src/Components/Universe';

// Take a list of transactions and return the top n categories
export const findTopCategories = (
  transactions: Transaction[],
  n: number = 3,
): Array<{
  categoryId: string;
  name: string;
  amount: number;
}> => {
  const categories = transactions.reduce((acc, tx) => {
    acc[tx.categoryId] ??= 0;

    acc[tx.categoryId] += tx.amount;

    return acc;
  }, {} as { [key: string]: number });

  const topCategories = Object.keys(categories).map((key) => ({
    categoryId: key,
    name:
      (categoryData.find((c) => c.id === key)?.primaryName || 'Unknown') +
      ' ' +
      (categoryData.find((c) => c.id === key)?.secondaryName || 'Unknown'),
    amount: categories[key],
  }));

  return topCategories
    .sort((a, b) => (a.amount > b.amount ? -1 : 1))
    .slice(0, n);
};
