import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useAPI } from '../../Components/API';

const Wrapper = styled.div`
  margin: 20px;
`;

const HelpText = styled.div`
  font-size: 12px;
  opacity: 0.8;
`;

const FormField = styled.div`
  margin: 10px 0;
`;

const Title = styled.div`
  font-size: 16px;
`;

const Toggle = styled.div`
  background: #000;
  width: 50px;
  height: 50px;
`;

const Input = styled.input<{
  invalid?: boolean;
}>`
  border: 1px solid ${(props) => (props.invalid ? '#f00' : '#ccc')};
  box-shadow: 0;
  outline: 0;
  padding: 8px;
  width: 100%;
  font-size: 18px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitButton = styled.div`
  padding: 10px;
  background: #e3f7ab;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  border-radius: 2px;
`;

const ToggleButton = styled.div<{
  selected: boolean;
}>`
  width: 30px;
  height: 30px;
  background: ${(props) => (props.selected ? '#fff' : '#262626')};
  border: 8px solid #262626;
  margin-right: 15px;
  margin-top: 15px;
`;

interface Props {
  initialData?: {
    id: string;
    title: string;
    date: string;
    period: string;
  };
}

const CreateSavings: React.FC<Props> = ({ initialData }) => {
  const [title, setTitle] = useState(initialData?.title ?? '');
  const [amount, setAmount] = useState<null | number>(null);
  const [date, setDate] = useState<Date>(dayjs().add(3, 'months').toDate());
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [type, setType] = useState<'CAPPED' | 'ROLLING'>('CAPPED');

  // Scale of 1-5
  const [strictDeduction, setStrictDeduction] = useState(true);

  const api = useAPI();

  async function handleSubmit() {
    const errors = [];

    if (!amount) {
      errors.push('amount');
    }

    if (!title) {
      errors.push('title');
    }

    console.debug(errors);

    setInvalidFields(errors);

    console.debug({
      title,
      amount,
      type,
      date,
    });

    if (errors.length) {
      return;
    }

    if (initialData?.id) {
    } else {
    }
  }

  console.debug(invalidFields);

  return (
    <Wrapper>
      <FormField>
        <Title>Title</Title>
        <Input
          value={title}
          invalid={invalidFields.includes('title')}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormField>
      <FormField>
        <Title>Savings type</Title>
      </FormField>
      <FormField>
        <Row onClick={() => setType('CAPPED')}>
          <div>
            <ToggleButton selected={type === 'CAPPED'} />
          </div>
          <div>
            <Title>Specific amount</Title>
            <HelpText>
              Save up to a specific amount, specified in number of salaries.
              Example: A buffer of 3 salaries.
            </HelpText>
          </div>
        </Row>
      </FormField>
      <FormField>
        <Row onClick={() => setType('ROLLING')}>
          <div>
            <ToggleButton selected={type === 'ROLLING'} />
          </div>
          <div>
            <Title>Rolling</Title>
            <HelpText>
              Aim to save a percentage of your salary each month with this
              savings type
            </HelpText>
          </div>
        </Row>
      </FormField>
      {type === 'CAPPED' && (
        <FormField>
          <Row>
            <div>
              <Title>Number of salaries as buffer: </Title>

              <HelpText>
                At least three months worth of income is recommended
              </HelpText>
            </div>
            <div>
              <Input
                type="number"
                invalid={invalidFields.includes('amount')}
                value={amount || ''}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </div>
          </Row>
        </FormField>
      )}

      {type === 'ROLLING' && (
        <FormField>
          <Row>
            <div>
              <Title>% of salary to save: </Title>

              <HelpText>
                At least three months worth of income is recommended
              </HelpText>
            </div>
            <div>
              <Input
                type="number"
                invalid={invalidFields.includes('amount')}
                value={amount || ''}
                onChange={(e) => setAmount(Number(e.target.value))}
              />
            </div>
          </Row>
        </FormField>
      )}
      <FormField>
        <Title>Importance</Title>
        <HelpText>
          How important is this savings goal to you? The higher the importance,
          the more money will be allocated to the goal in relation to others.
        </HelpText>
      </FormField>

      <FormField>
        <Row onClick={() => setStrictDeduction(true)}>
          <div>
            <ToggleButton selected={strictDeduction} />
          </div>
          <div>
            <Title>Crucial</Title>
            <HelpText>
              Money will be deducted before you have a chance to spend it
            </HelpText>
          </div>
        </Row>
      </FormField>
      <FormField>
        <Row onClick={() => setStrictDeduction(false)}>
          <div>
            <ToggleButton selected={!strictDeduction} />
          </div>
          <div>
            <Title>Nice to have</Title>
            <HelpText>
              Money will be allocated if you have spent less than your daily
              budget
            </HelpText>
          </div>
        </Row>
      </FormField>
      <SubmitButton onClick={handleSubmit}>Create</SubmitButton>
    </Wrapper>
  );
};

export default CreateSavings;
